import React from "react";

const DoubleArrowRight = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
      id="double-arrow-right"
    >
      <path
        stroke={stroke}
        stroke-width="8"
        d="M38.522 33L69.0439 63.522L38.522 94.0439"
      ></path>
      <path
        stroke={stroke}
        stroke-width="8"
        d="M62.5439 33L93.0659 63.522L62.5439 94.0439"
      ></path>
    </svg>
  );
};

export default DoubleArrowRight;
