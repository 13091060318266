import { Link } from "react-router-dom";
import { motion, useInView, useAnimation, delay } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const MobileNav = ({ page, setOpenMenu, mobNavRef }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  const [isHomeExpanded, setIsHomeExpanded] = useState(false);
  const toggleHomeAccordion = () => {
    setIsHomeExpanded(!isHomeExpanded);
  };

  // Scroll function to scroll to the corresponding section
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setOpenMenu(false);
    }
  };

  // Motion variants for animation
  const navVariants = {
    hidden: { opacity: 0, y: 75 },
    visible: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div className="mobile-nav-container" ref={mobNavRef}>
      <motion.nav
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 0.5, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1.5 }}
        ref={ref}
        class="mobile-nav"
      >
        <ul class="nav-listt">
          <li className="nav-itemm">
            <div
              className={`accordion-item ${isHomeExpanded ? "expanded" : ""}`}
            >
              <Link
                to={"/"}
                className="accordion-header"
                onClick={toggleHomeAccordion}
              >
                Home <span className="dropdown-arrow">&#9662;</span>
              </Link>

              {isHomeExpanded && (
                <ul className="accordion-content">
                  {[
                    { title: "About Company", id: "about-company" },
                    { title: "Our Service", id: "our-service" },
                    { title: "Case Studies", id: "case-studies" },
                    { title: "Why Werkix", id: "why-werkix" },
                    { title: "Meet Our Team", id: "meet-team" },
                    { title: "Talent Testimonies", id: "talent-testimonies" },
                    {
                      title: "Clients Testimonials",
                      id: "clients-testimonials",
                    },
                    { title: "Blog & Articles", id: "blog-articles" },
                  ].map((item, index) => (
                    <li
                      key={index}
                      className="accordion-link"
                      onClick={() => scrollToSection(item.id)}
                    >
                      {item.title}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </li>
          <motion.li
            variants={{
              hidden: { opacity: 0, x: 75 },
              visible: { opacity: 0.5, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 2.5 }}
            ref={ref}
            class="nav-itemm"
          >
            <Link
              to={"/about"}
              href="#"
              className={`nav-linkk ${page == "about" ? "active" : ""}`}
              onClick={() => setOpenMenu(false)}
            >
              About
            </Link>
          </motion.li>
          <motion.li
            variants={{
              hidden: { opacity: 0, x: 75 },
              visible: { opacity: 0.5, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 3.5 }}
            ref={ref}
            class="nav-itemm"
          >
            <Link
              to={"/ourtalents"}
              href="#"
              className={`nav-linkk ${page == "our talents" ? "active" : ""}`}
              onClick={() => setOpenMenu(false)}
            >
              Our Talents
            </Link>
          </motion.li>
          <motion.li
            variants={{
              hidden: { opacity: 0, x: 75 },
              visible: { opacity: 0.5, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 4.0 }}
            ref={ref}
            class="nav-itemm"
          >
            <Link
              to={"/clients"}
              href="#"
              className={`nav-linkk ${page == "clients" ? "active" : ""}`}
              onClick={() => setOpenMenu(false)}
            >
              Clients
            </Link>
          </motion.li>
          <motion.li
            variants={{
              hidden: { opacity: 0, x: 75 },
              visible: { opacity: 0.5, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 4.5 }}
            ref={ref}
            class="nav-itemm"
          >
            <Link
              to={"/blog"}
              href="#"
              className={`nav-linkk ${page == "blog" ? "active" : ""}`}
              onClick={() => setOpenMenu(false)}
            >
              What's New
            </Link>
          </motion.li>
          <motion.li
            variants={{
              hidden: { opacity: 0, x: 75 },
              visible: { opacity: 0.5, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 5 }}
            ref={ref}
            class="nav-itemm"
          >
            <Link
              to={"/contact"}
              href="#"
              className={`nav-linkk ${page == "contact" ? "active" : ""}`}
              onClick={() => setOpenMenu(false)}
            >
              Contact
            </Link>
          </motion.li>
        </ul>
      </motion.nav>
      <span className="close-icon" onClick={() => setOpenMenu(false)}>
        X
      </span>
    </div>
  );
};

export default MobileNav;
