import React from "react";

const SBackgroundRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="180px"
      height="800px"
      // style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          // style="opacity:0.392"
          fill="#7ac04a"
          d="M 284.5,49.5 C 284.5,54.1667 284.5,58.8333 284.5,63.5C 270.249,57.0454 255.916,50.5454 241.5,44C 233.276,40.2188 225.276,35.8855 217.5,31C 221.434,28.116 225.601,25.616 230,23.5C 248.151,31.9903 266.318,40.657 284.5,49.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.392"
          fill="#7abf4a"
          d="M 284.5,113.5 C 284.5,118.167 284.5,122.833 284.5,127.5C 242.291,107.733 199.958,87.8995 157.5,68C 156.978,67.4387 156.645,66.772 156.5,66C 161.455,60.4582 167.455,58.7915 174.5,61C 210.744,78.9983 247.41,96.4983 284.5,113.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.391"
          fill="#7abf4a"
          d="M 284.5,177.5 C 284.5,182.167 284.5,186.833 284.5,191.5C 221.093,162.296 158.093,132.296 95.5,101.5C 100.458,94.2288 106.791,92.7288 114.5,97C 171.079,123.921 227.746,150.754 284.5,177.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.392"
          fill="#7abf4a"
          d="M 284.5,241.5 C 284.5,246.167 284.5,250.833 284.5,255.5C 240.529,234.478 196.529,213.978 152.5,194C 118.66,177.247 84.6604,160.747 50.5,144.5C 52.3056,141.528 54.3056,138.695 56.5,136C 58.1667,135.333 59.8333,135.333 61.5,136C 135.839,171.17 210.173,206.336 284.5,241.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.393"
          fill="#79bf4a"
          d="M 284.5,305.5 C 284.5,310.167 284.5,314.833 284.5,319.5C 197.701,278.439 110.701,237.272 23.5,196C 22.9781,195.439 22.6448,194.772 22.5,194C 23.1714,190.836 24.5047,188.003 26.5,185.5C 27.365,184.417 28.365,184.251 29.5,185C 114.567,225.202 199.567,265.369 284.5,305.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.392"
          fill="#79bf4a"
          d="M 284.5,369.5 C 284.5,374.167 284.5,378.833 284.5,383.5C 193.076,340.59 101.743,297.423 10.5,254C 9.3568,249.467 9.69013,244.967 11.5,240.5C 102.401,283.785 193.401,326.785 284.5,369.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.392"
          fill="#7abf4a"
          d="M 284.5,433.5 C 284.5,438.167 284.5,442.833 284.5,447.5C 193.908,404.383 103.242,361.55 12.5,319C 11.9747,318.308 11.3081,317.808 10.5,317.5C 9.50776,313.217 9.17443,308.884 9.5,304.5C 101.216,347.722 192.883,390.722 284.5,433.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.39"
          fill="#7ac04a"
          d="M 284.5,497.5 C 284.5,501.833 284.5,506.167 284.5,510.5C 283.127,510.657 281.793,510.49 280.5,510C 190.49,467.328 100.49,424.661 10.5,382C 9.19986,377.55 9.19986,373.05 10.5,368.5C 101.542,412.02 192.875,455.02 284.5,497.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.391"
          fill="#79bf4a"
          d="M 284.5,561.5 C 284.5,566.167 284.5,570.833 284.5,575.5C 192.9,532.251 101.4,488.918 10,445.5C 9.17248,441.129 9.33915,436.795 10.5,432.5C 101.903,475.537 193.236,518.537 284.5,561.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.391"
          fill="#7abf4a"
          d="M 284.5,625.5 C 284.5,630.167 284.5,634.833 284.5,639.5C 193.195,596.656 101.862,553.489 10.5,510C 9.19986,505.55 9.19986,501.05 10.5,496.5C 101.562,540.031 192.896,583.031 284.5,625.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.39"
          fill="#79bf4a"
          d="M 284.5,690.5 C 284.5,694.833 284.5,699.167 284.5,703.5C 202.633,664.8 120.633,625.966 38.5,587C 34.5448,582.262 31.2115,577.095 28.5,571.5C 30.7657,570.794 33.0991,570.961 35.5,572C 118.61,611.838 201.61,651.338 284.5,690.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.004"
          fill="#000000"
          d="M 284.5,754.5 C 284.5,754.833 284.5,755.167 284.5,755.5C 283.83,755.019 283.83,754.685 284.5,754.5 Z"
        />
      </g>
    </svg>
  );
};

export default SBackgroundRight;
