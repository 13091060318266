import React from "react";
import LocationIcon from "../../svg/location";
import InstagramIcon from "../../svg/socialIcons/instagram";
import MetaIcon from "../../svg/socialIcons/meta";
import LinkedInIcon from "../../svg/socialIcons/linkedIn";

const TopHeader = () => {
  return (
    <div className="top-header">
      <div className="top-header-wrap container">
        <div className="location">
          {" "}
          <LocationIcon />
          Blantyre, Malawi, Africa
        </div>
        <div className="socials">
          <a
            target="_blank"
            rel="nooper noreferrer"
            href="https://www.instagram.com/werkix.io/"
            className="social-item fb"
          >
            <InstagramIcon />
          </a>
          <a
            target="_blank"
            rel="nooper noreferrer"
            href="https://www.facebook.com/profile.php?id=100086435430979"
            className="social-item ig"
          >
            <MetaIcon />
          </a>
          <a
            target="_blank"
            rel="nooper noreferrer"
            href="https://www.linkedin.com/company/werkix/"
            className="social-item lI"
          >
            <LinkedInIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
