import React from "react";

const ClientPairingIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill={fill}
    >
      <g clip-path="url(#clip0_275_1729)">
        <path
          d="M68.9062 0H1.09375C0.803669 0 0.52547 0.115234 0.320352 0.320352C0.115234 0.52547 0 0.803669 0 1.09375L0 53.5938C0 53.8838 0.115234 54.162 0.320352 54.3671C0.52547 54.5723 0.803669 54.6875 1.09375 54.6875H35C35.2901 54.6875 35.5683 54.5723 35.7734 54.3671C35.9785 54.162 36.0938 53.8838 36.0938 53.5938C36.0938 53.3037 35.9785 53.0255 35.7734 52.8204C35.5683 52.6152 35.2901 52.5 35 52.5H2.1875V10.9375H67.8125V52.5H65.625C65.3349 52.5 65.0567 52.6152 64.8516 52.8204C64.6465 53.0255 64.5312 53.3037 64.5312 53.5938C64.5312 53.8838 64.6465 54.162 64.8516 54.3671C65.0567 54.5723 65.3349 54.6875 65.625 54.6875H68.9062C69.1963 54.6875 69.4745 54.5723 69.6796 54.3671C69.8848 54.162 70 53.8838 70 53.5938V1.09375C70 0.803669 69.8848 0.52547 69.6796 0.320352C69.4745 0.115234 69.1963 0 68.9062 0ZM67.8125 8.75H2.1875V2.1875H67.8125V8.75Z"
          fill={fill}
        />
        <path
          d="M61.25 24.0625H39.375C39.0849 24.0625 38.8067 24.1777 38.6016 24.3829C38.3965 24.588 38.2812 24.8662 38.2812 25.1562V68.9062C38.2812 69.1963 38.3965 69.4745 38.6016 69.6796C38.8067 69.8848 39.0849 70 39.375 70H61.25C61.5401 70 61.8183 69.8848 62.0234 69.6796C62.2285 69.4745 62.3438 69.1963 62.3438 68.9062V25.1562C62.3438 24.8662 62.2285 24.588 62.0234 24.3829C61.8183 24.1777 61.5401 24.0625 61.25 24.0625ZM60.1562 67.8125H40.4688V26.25H60.1562V67.8125Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_275_1729">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClientPairingIcon;
