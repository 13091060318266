import ClockIcon from "../svg/contactPage/clockIcon";
import EnvelopIcon from "../svg/contactPage/envelopIcon";
import LocationIcon from "../svg/contactPage/locationIcon";
import PhoneIcon from "../svg/contactPage/phoneIcon";

const Contacts = [
  {
    cardId: "01",
    icon: <PhoneIcon />,
    primaryText: "+265 987 65 45 14",
  },
  {
    cardId: "02",
    icon: <EnvelopIcon />,
    primaryText: "hello@werkix.io",
  },
  {
    cardId: "03",
    icon: <ClockIcon />,
    primaryText: "Office open 9AM - 17PM",
  },
  {
    cardId: "04",
    icon: <LocationIcon />,
    primaryText: "Blantyre, Malawi, Africa.",
    secondaryText: "New York, United States",
    alternativeText: "London, England",
    location: "location",
  },
];

export default Contacts;
