const teamMembers = [
  {
    id: 1,
    name: "Jacob Mbendera",
    position: "Tech Team Lead/Developer",
    statement:
      "A highly skilled Full Stack Developer with extensive experience in IT solutions and business administration. With over five years of experience in front-end and back-end technologies, Jacob possesses a diverse set of skills in programming, design, and management.",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1721116608/werkix/whatsnew/tm-jacob_lj3q3j.jpg",
    contact: {
      phone: "+0987654321",
      email: "jacob@werkix.io",
      location: "Blantyre, Malawi, Africa",
    },
  },
  {
    id: 2,
    name: "Matthews Ziba",
    position: "Technical Recruiter/IT Support",
    statement:
      "Mathews' background primarily lies in Defensive Security as a proactive Cybersecurity Professional, specializing in Blue Teaming. The majority of his experience stems from my roles as a SOC analyst and Network Administrator.",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1721116605/werkix/whatsnew/tm-matt_skg8ca.jpg",
    contact: {
      phone: "+0987654321",
      email: "mathews@werkix.io",
      location: "Blantyre, Malawi, Africa",
    },
  },
  {
    id: 3,
    name: "Milica Krstic ",
    position: "HR Manager",
    statement:
      "With 5 years of experience as a Recruiter for IT and digital marketing, she’s excelled in working with international clients and teams and supporting career growth. Working with multiple industries, she helped a lot of clients to build and manage teams remote and on-site with great success.",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1722851703/werkix/1721907509594_ztpua7.jpg",
    contact: {
      phone: "+381611685578",
      email: "milica@werkix.io",
      location: "Belgrade, Serbia",
    },
  },
];

export default teamMembers;
