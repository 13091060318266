import React from "react";
import "./contactCard.styles.css";
import { motion, useInView, useAnimation, delay } from "framer-motion";

const ContactCard = ({
  icon,
  primaryText,
  secondaryText,
  alternativeText,
  cardNumber,
  location,
}) => {
  return (
    <motion.div
      whileHover={{ scale: 1.08 }}
      whileTap={{ scale: 1.1 }}
      drag="x"
      dragConstraints={{ bottom: -100, top: 100 }}
      className={`${location ? "contact-card locationCard" : "contact-card"}`}
    >
      <div className="contact-cardBtn">
        <span className="card-number">{cardNumber}</span>
      </div>
      <i>{icon}</i>
      <p>{primaryText}</p>
      {location && (
        <>
          <p>{secondaryText}</p>
          <p>{alternativeText}</p>
        </>
      )}
    </motion.div>
  );
};

export default ContactCard;
