import React, { useEffect, useState } from "react";
import "./talentTestimonials.styles.css";
import OurTalentCard from "../cards/ourTalentCard/ourTalentCard";
import talentTestimonies from "../../data/talentTestimony";
import { shuffleArray } from "../../helpers/shuffleArray";

const TalentTestimonials = () => {
  const [randomTestimonials, setRandomTestimonials] = useState([]);

  const getRandomTestimonials = (testimonials) => {
    const shuffledTestimonials = shuffleArray(testimonials);
    return shuffledTestimonials.slice(0, 2);
  };

  useEffect(() => {
    setRandomTestimonials(getRandomTestimonials(talentTestimonies));
  }, []);
  return (
    <div id="talent-testimonies" className="talent-testimonials">
      <div className="talent-container">
        <div className="section-header">
          <h1 className="home-title">ABOUT THE COMPANY</h1>
          <h2 className="home-heading">What Our Talent Says</h2>
        </div>
        <div className="testimonials">
          {randomTestimonials.map((testimony, i) => (
            <OurTalentCard
              name={testimony.fullName}
              profile={testimony.profile}
              content={testimony.testimony}
              specialisation={testimony.position}
              key={i + 1}
              primary={(i + 1) % 2 !== 0}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TalentTestimonials;
