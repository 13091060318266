import React from "react";

const ServiceMainCard = ({ service }) => {
  return (
    <div className="service-item">
      <div className="service">
        <div className="service-title">
          {service.icon}
          <h3>
            {service.title.main}
            {service.title.subtitle}
          </h3>
        </div>

        <ul className="service-description">
          <li>{service.serviceDesc.primary}</li>
          <br />
          <li>{service.serviceDesc.secondary}</li>
        </ul>
      </div>

      <div className="service-divider"></div>
    </div>
  );
};

export default ServiceMainCard;
