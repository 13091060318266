import React from "react";

const SendFormArrow = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
    >
      <path
        d="M0.418726 17.336L19.5698 1.26629M19.5698 1.26629L18.5767 11.0743M19.5698 1.26629L9.7384 0.541172"
        stroke={stroke}
      />
    </svg>
  );
};

export default SendFormArrow;
