import "./header.styles.css";
import React, { useRef, useState } from "react";
import TopHeader from "./topHeader.component";
import Nav from "../nav/nav.component";
import BannerNav from "../banner-nav/bannerNav.component";
import TopHeaderMobile from "./topHeaderMobile.component";
import MobileNav from "../nav/mobileNav.component";
import useClickOutside from "../../helpers/clickOutside.helper";

const Header = ({ page }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const mobNavRef = useRef(null);
  useClickOutside(mobNavRef, () => {
    setOpenMenu(false);
  });

  return (
    <div className="main-header ">
      <TopHeader />
      <TopHeaderMobile setOpenMenu={setOpenMenu} />
      {openMenu && (
        <MobileNav setOpenMenu={setOpenMenu} mobNavRef={mobNavRef} />
      )}
      {/* <BannerNav /> */}
    </div>
  );
};

export default Header;
