const clientTestimonies = [
  {
    fullName: "Jason Storey",
    position: "CEO",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1720833081/werkix/clients-logos/stratis-edt.png",
    testimony:
      "Working with Werkix has been a game-changer for Stratis Global. Their Salesforce specialists and full-stack engineers have seamlessly integrated into our team, delivering high-quality results and constantly upskilling. We value our partnership with Werkix and the exceptional talent they provide.",
  },
  {
    fullName: "Gentry",
    position: "CEO",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1720822247/werkix/clients-logos/rojoli-services_ajmn9x.png",
    testimony:
      "Werkix has been essential in scaling our support operations. Their Microsoft-certified engineers are skilled, adaptable, and handle tickets efficiently across our tools. We’re highly satisfied with their quality,dedication and how they fit right into our Atlanta team.",
  },

  {
    fullName: "Carl Meyer,",
    position: "Project Manager",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1720822247/werkix/clients-logos/pewani_vbs97p.png",
    testimony:
      "Werkix developers have been an incredible asset to our Product team at Pewani. Their technical expertise and dedication have helped us reach project milestones seamlessly. Their professionalism and quick adaptability continue to exceed our expectations.",
  },
];
export default clientTestimonies;
