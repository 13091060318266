import React from "react";
import "./joinProcessCard.styles.css";
import SendFormArrow from "../../../svg/contactPage/sendFormArrow";

const JoinProcessCard = ({ processIcon, processName, processDesc }) => {
  return (
    <div className="process-card">
      <i className="card-icon">{processIcon}</i>
      <div className="process">{processName}</div>
      <div className="process-desc">{processDesc}</div>
      <div className="card-arrow">
        <SendFormArrow />
      </div>
    </div>
  );
};

export default JoinProcessCard;
