import React from "react";

const Diversity = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clip-path="url(#clip0_744_902)">
        <path
          d="M20 14.375C18.8875 14.375 17.7999 14.7049 16.8749 15.323C15.9499 15.9411 15.2289 16.8196 14.8032 17.8474C14.3774 18.8752 14.266 20.0062 14.4831 21.0974C14.7001 22.1885 15.2359 23.1908 16.0225 23.9775C16.8092 24.7641 17.8115 25.2999 18.9026 25.5169C19.9938 25.734 21.1248 25.6226 22.1526 25.1968C23.1804 24.7711 24.0589 24.0501 24.677 23.1251C25.2951 22.2001 25.625 21.1125 25.625 20C25.6232 18.5087 25.03 17.079 23.9755 16.0245C22.921 14.97 21.4913 14.3768 20 14.375ZM20 24.375C19.1347 24.375 18.2888 24.1184 17.5694 23.6377C16.8499 23.1569 16.2892 22.4737 15.958 21.6742C15.6269 20.8748 15.5403 19.9951 15.7091 19.1465C15.8779 18.2978 16.2946 17.5183 16.9064 16.9064C17.5183 16.2946 18.2978 15.8779 19.1465 15.7091C19.9951 15.5403 20.8748 15.6269 21.6742 15.958C22.4737 16.2892 23.1569 16.8499 23.6377 17.5694C24.1184 18.2888 24.375 19.1347 24.375 20C24.3737 21.1599 23.9123 22.2719 23.0921 23.0921C22.2719 23.9123 21.1599 24.3737 20 24.375Z"
          fill="#67FB94"
        />
        <path
          d="M28.5113 11.827C28.587 11.8584 28.668 11.8747 28.75 11.8751H38.125C38.2908 11.8751 38.4497 11.8093 38.5669 11.6921C38.6842 11.5749 38.75 11.4159 38.75 11.2501C38.75 11.0844 38.6842 10.9254 38.5669 10.8082C38.4497 10.691 38.2908 10.6251 38.125 10.6251H30.2588L39.8169 1.06701C39.9307 0.949131 39.9937 0.791255 39.9923 0.627382C39.9909 0.463509 39.9251 0.306751 39.8093 0.190871C39.6934 0.0749907 39.5366 0.00926014 39.3728 0.00783613C39.2089 0.00641211 39.051 0.0694086 38.9331 0.183257L29.375 9.74138V1.87513C29.375 1.70937 29.3092 1.5504 29.1919 1.43319C29.0747 1.31598 28.9158 1.25013 28.75 1.25013C28.5842 1.25013 28.4253 1.31598 28.3081 1.43319C28.1908 1.5504 28.125 1.70937 28.125 1.87513V11.2501C28.1254 11.3321 28.1417 11.4132 28.1731 11.4889C28.2366 11.642 28.3582 11.7636 28.5113 11.827Z"
          fill="#67FB94"
        />
        <path
          d="M11.2496 1.25013C11.0839 1.25013 10.9249 1.31598 10.8077 1.43319C10.6905 1.5504 10.6246 1.70937 10.6246 1.87513V9.74138L1.06652 0.183257C0.948643 0.0694086 0.790766 0.00641211 0.626893 0.00783613C0.46302 0.00926014 0.306263 0.0749907 0.190383 0.190871C0.0745024 0.306751 0.00877186 0.463509 0.00734785 0.627382C0.00592383 0.791255 0.0689203 0.949131 0.182769 1.06701L9.74089 10.6251H1.87464C1.70888 10.6251 1.54991 10.691 1.4327 10.8082C1.31549 10.9254 1.24964 11.0844 1.24964 11.2501C1.24964 11.4159 1.31549 11.5749 1.4327 11.6921C1.54991 11.8093 1.70888 11.8751 1.87464 11.8751H11.2496C11.4154 11.8751 11.5744 11.8093 11.6916 11.6921C11.8088 11.5749 11.8746 11.4159 11.8746 11.2501V1.87513C11.8746 1.70937 11.8088 1.5504 11.6916 1.43319C11.5744 1.31598 11.4154 1.25013 11.2496 1.25013Z"
          fill="#67FB94"
        />
        <path
          d="M30.2588 29.375H38.125C38.2908 29.375 38.4497 29.3092 38.5669 29.1919C38.6842 29.0747 38.75 28.9158 38.75 28.75C38.75 28.5842 38.6842 28.4253 38.5669 28.3081C38.4497 28.1908 38.2908 28.125 38.125 28.125H28.75C28.5842 28.125 28.4253 28.1908 28.3081 28.3081C28.1908 28.4253 28.125 28.5842 28.125 28.75V38.125C28.125 38.2908 28.1908 38.4497 28.3081 38.5669C28.4253 38.6842 28.5842 38.75 28.75 38.75C28.9158 38.75 29.0747 38.6842 29.1919 38.5669C29.3092 38.4497 29.375 38.2908 29.375 38.125V30.2588L38.9331 39.8169C39.051 39.9307 39.2089 39.9937 39.3728 39.9923C39.5366 39.9909 39.6934 39.9251 39.8093 39.8093C39.9251 39.6934 39.9909 39.5366 39.9923 39.3728C39.9937 39.2089 39.9307 39.051 39.8169 38.9331L30.2588 29.375Z"
          fill="#67FB94"
        />
        <path
          d="M11.4886 28.1731C11.413 28.1414 11.3319 28.1251 11.2499 28.125H1.87487C1.70911 28.125 1.55014 28.1908 1.43293 28.3081C1.31572 28.4253 1.24987 28.5842 1.24987 28.75C1.24987 28.9158 1.31572 29.0747 1.43293 29.1919C1.55014 29.3092 1.70911 29.375 1.87487 29.375H9.74112L0.182993 38.9331C0.123299 38.9908 0.0756849 39.0597 0.0429293 39.136C0.0101736 39.2122 -0.00706777 39.2943 -0.0077889 39.3772C-0.00851004 39.4602 0.00730353 39.5425 0.0387291 39.6193C0.0701546 39.6962 0.116563 39.7659 0.175246 39.8246C0.233929 39.8833 0.303711 39.9297 0.380521 39.9611C0.457332 39.9926 0.539631 40.0084 0.622618 40.0077C0.705605 40.0069 0.787618 39.9897 0.86387 39.9569C0.940123 39.9242 1.00909 39.8766 1.06674 39.8169L10.6249 30.2588V38.125C10.6249 38.2908 10.6907 38.4497 10.8079 38.5669C10.9251 38.6842 11.0841 38.75 11.2499 38.75C11.4156 38.75 11.5746 38.6842 11.6918 38.5669C11.809 38.4497 11.8749 38.2908 11.8749 38.125V28.75C11.8745 28.668 11.8581 28.587 11.8267 28.5113C11.7633 28.3582 11.6417 28.2366 11.4886 28.1731Z"
          fill="#67FB94"
        />
      </g>
      <defs>
        <clipPath id="clip0_744_902">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Diversity;
