import React from "react";

const ArrowRightNofill = ({ fill }) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.158204 6.375C0.158204 5.77734 0.623048 5.3125 1.1875 5.3125L11.3809 5.3125L7.89453 1.79297C7.46289 1.39453 7.46289 0.697265 7.89453 0.298828C8.09375 0.0996088 8.35938 -5.80542e-07 8.625 -5.5732e-07C8.92383 -5.31196e-07 9.18945 0.0996089 9.38867 0.298828L14.7012 5.61133C15.1328 6.00977 15.1328 6.70703 14.7012 7.10547L9.38867 12.418C8.99023 12.8496 8.29297 12.8496 7.89453 12.418C7.46289 12.0195 7.46289 11.3223 7.89453 10.9238L11.3809 7.4375L1.1875 7.4375C0.623048 7.4375 0.158204 6.93945 0.158204 6.375Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowRightNofill;
