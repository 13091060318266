import React, { useRef, useState } from "react";
import "./ourTalents.style.css";
import PageHeader from "../../components/pageHeader/pageHeader.component.jsx";
import SendApplicationIcon from "../../svg/services/sendApplicationIcon.js";
import PhoneScreening from "../../svg/services/phoneScreening.js";
import JoinProcessCard from "../../components/cards/joinProcessCard/joinProcessCard.component.jsx";
import InterviewIcon from "../../svg/services/interview.js";
import CodeAssessmentIcon from "../../svg/services/codeAssessment.js";
import CandidateReportIcon from "../../svg/services/candidateReport.js";
import JobSourcingIcon from "../../svg/services/jobSourcing.js";
import ClientPairingIcon from "../../svg/services/clientPairing.js";
import OnboardingIcon from "../../svg/services/onboarding.js";
import SlidingText from "../../components/slidingText/slidingText.component.jsx";
import TeamMemberCard from "../../components/cards/teamMember/teamMember.component.jsx";
import teamMembers from "../../data/teamMembers.js";
import XVectorComponent from "../../components/XVector/xVector.component.jsx";
import WhyUsHome from "../../components/WhyUsHome/whyUsHome.component.jsx";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import { getError } from "../../helpers/errors.helper.js";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import InputComponent from "../../components/inputs/inputField.component.jsx";
import TextArea from "../../components/inputs/textArea.component.jsx";
import SendFormArrow from "../../svg/contactPage/sendFormArrow.js";
import ProceedIcon from "../../svg/services/proceedIcon.js";
import AttachedFileIcon from "../../svg/attached.js";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderButtonRight from "../../components/slider-button/sliderButtonRight.component.jsx";
import SliderButtonLeft from "../../components/slider-button/sliderButtonLeft.component.jsx";

const OurTalentsPage = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [attachments, setAttachments] = useState([]);
  const refInput = useRef(null);

  const contactInfo = {
    fullName: "",
    email: "",
    linkedin: "",
    github: "",
    message: "",
  };
  const [contact, setContact] = useState(contactInfo);
  const { fullName, email, github, message, linkedin } = contact;
  const handleContact = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleValidation = Yup.object({
    fullName: Yup.string()
      .required("Name is required")
      .min(2, "name but have more than 2 character")
      .max(16, "name must be less than 16 characters")
      .matches(/[aA-zZ]+$/, "Number and special characters are not allowed"),
    email: Yup.string()
      .required("Email Address is required.")
      .email("Invalid email address"),
    linkedin: Yup.string().notRequired(),
    github: Yup.string().nullable(),
    message: Yup.string().required("message is required").min(20).max(500),
    attachments: Yup.mixed()
      .test("fileSize", "File size is too large", (files) => {
        if (files) {
          for (let i = 0; i < files.length; i++) {
            if (files[i].size > 10 * 1024 * 1024) {
              // 10 MB limit
              return false;
            }
          }
        }
        return true;
      })
      .test("fileType", "Unsupported File Format", (files) => {
        if (files) {
          for (let i = 0; i < files.length; i++) {
            if (
              ![
                "application/pdf",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ].includes(files[i].type)
            ) {
              return false;
            }
          }
        }
        return true;
      }),
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("email", email);
      formData.append("linkedin", linkedin);
      formData.append("github", github);
      formData.append("message", message);

      attachments.forEach((file, index) => {
        formData.append(`attachments`, file);
      });

      const { data } = await axios.post(
        "https://werkix-mailing-service-6a39d7327f11.herokuapp.com/api/email/apply",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setError("");
      setSuccess(data.message);

      setTimeout(() => {
        navigate("/");
      }, 2000);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(getError(err));
    }
  };

  // Handle file input changes
  const handleFileChange = (event) => {
    const files = event.target.files;
    setAttachments(Array.from(files)); // Convert FileList to Array
  };

  return (
    <div className="ourTalent-page">
      <PageHeader pageName={"our talents"} />

      <div className="services-container">
        <div className="home-title">How To Join Us</div>
        <div className="home-heading">We’re Offering Best Solution </div>
        <div className="join-process">
          <JoinProcessCard
            processIcon={<SendApplicationIcon />}
            processName={"Send Your Application"}
            processDesc={
              " Send in your application take a leap, lets see what you are about"
            }
          />
          <JoinProcessCard
            processIcon={<PhoneScreening />}
            processName={"Phone Screening"}
            processDesc={" Lets have a chat to go over your submission"}
          />
          <JoinProcessCard
            processIcon={<InterviewIcon />}
            processName={"Interview"}
            processDesc={
              "You’ve been considered for an interview we get to know each other"
            }
          />
          <JoinProcessCard
            processIcon={<CodeAssessmentIcon />}
            processName={"Coding Assessment"}
            processDesc={"We test your skills and determine your level"}
          />
          <JoinProcessCard
            processIcon={<CandidateReportIcon />}
            processName={"Candidate Report"}
            processDesc={"We test your skills and determine your level"}
          />
          <JoinProcessCard
            processIcon={<JobSourcingIcon />}
            processName={"Job Sourcing"}
            processDesc={"We search to determine which jobs are a fit for you"}
          />
          <JoinProcessCard
            processIcon={<ClientPairingIcon />}
            processName={"Client Pairing"}
            processDesc={
              "We schedule a joint call with client so they can get to know you"
            }
          />
          <JoinProcessCard
            processIcon={<OnboardingIcon />}
            processName={"Onboarding"}
            processDesc={
              "We now go through an onboarding process to incubate and train you"
            }
          />
        </div>
      </div>

      <div className="ourTalent-text-slider">
        <SlidingText />
      </div>
      <div className="team-members">
        <div className="team-members-wrap">
          <div className="head-section">
            <div className="team-member-vector">
              <XVectorComponent />
            </div>
            <h3>
              {" "}
              Professional <br />
              Team <br />
              Members{" "}
            </h3>
          </div>
          <div className="profile">
            {teamMembers.map((member) => (
              <TeamMemberCard
                id={member.id}
                mainCard
                image={member.image}
                name={member.name}
                position={member.position}
                key={member.id}
              />
            ))}
          </div>
        </div>
      </div>

      <WhyUsHome />

      <div className="contact-form-container">
        <div className="contact-form">
          <div className="home-title">Contact us now</div>
          <div className="home-heading">you want to work with us</div>
          <div className="fill-form">Fill out the form</div>
          <p className="form-desc">
            Working for a tech recruitment agency can provide a clear career
            path and opportunities. Feel free to send your CV or check all
            available jobs on the LinkedIn platform..
          </p>
          <Formik
            enableReinitialize
            validationSchema={handleValidation}
            initialValues={{ fullName, linkedin, github, email, message }}
            onSubmit={() => {
              handleSubmit();
            }}
          >
            {(form) => (
              <Form className="form-content">
                {" "}
                <div className="name-email">
                  <InputComponent
                    type="text"
                    placeholder="Your Name"
                    name="fullName"
                    onChange={handleContact}
                  />
                  <InputComponent
                    type="text"
                    name="email"
                    placeholder="Your Email"
                    onChange={handleContact}
                  />
                </div>
                <InputComponent
                  type="text"
                  name="linkedin"
                  placeholder="Your LinkedIn"
                  onChange={handleContact}
                />
                <InputComponent
                  type="text"
                  name="github"
                  placeholder="Your Github"
                  onChange={handleContact}
                />
                <TextArea
                  name="message"
                  placeholder="Write your message"
                  onChange={handleContact}
                ></TextArea>
                <div className="erro">{error}</div>
                <div
                  className="attach-cv-button"
                  onClick={() => refInput.current.click()}
                >
                  <span className="attach-cv-square top-left"></span>
                  <span className="attach-cv-square top-right"></span>
                  <span className="attach-cv-square bottom-left"></span>
                  <span className="attach-cv-square bottom-right"></span>
                  <SendFormArrow /> Attach CV
                </div>
                {error && <div className="input-error">{error}</div>}
                {/* File input for attachments */}
                <div className="form-group hidden-input">
                  <input
                    type="file"
                    hidden
                    name="attachments"
                    onChange={handleFileChange}
                    multiple
                    accept=".pdf,.docx"
                    className="form-control-file"
                    ref={refInput}
                  />
                </div>
                <div className="file-list">
                  {attachments.map((file, index) => (
                    <div key={index} className="file-item">
                      <span className="file-icon">
                        <AttachedFileIcon />
                      </span>{" "}
                      {file.name}
                    </div>
                  ))}
                </div>
                {success && <div className="input-success">{success}</div>}
                <button type="submit" className="submit-btn">
                  Send Application <SendFormArrow stroke={"#0C0F14"} />
                </button>
                <PulseLoader
                  color="#67fb94"
                  loading={loading}
                  size={15}
                  speedMultiplier={1}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Form>
            )}
          </Formik>
        </div>

        {/* <div className="contact-jobs">
          <h2>Jobs(2)</h2>
          <div className="job-category">
            <a href="#" className="category">
              All projects
            </a>
            <a href="#" className="category">
              Software development
            </a>
            <a href="#" className="category">
              Full Time
            </a>
            <a href="#" className="category">
              Full Time9
            </a>
            <a href="#" className="category">
              Design
            </a>
          </div>
          <div className="highlighter"></div>

          <div className="job-breakdown">
            <ol>
              <li className="job-title">
                <div>
                  Product Designerspan <br />
                  <span className="job-count">(1 job available)</span>
                </div>
              </li>
              <li className="job-title">
                <div>
                  Product Designerspan <br />
                  <span className="job-count">(1 job available)</span>
                </div>
              </li>
            </ol>
          </div>
          <a href="#" className="linkedin-redirect">
            More information on Linkedin{" "}
            <ProceedIcon fill={"#67FB94"} width={19} height={19} />
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default OurTalentsPage;
