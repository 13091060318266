const logos = [
  {
    clintName: "powani labs",
    website: "https://www.pewanilabs.com/",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1720822247/werkix/clients-logos/pewani_vbs97p.png",
  },
  {
    clintName: "Stratis Global",
    website: "https://www.stratisglobal.com",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1720833081/werkix/clients-logos/stratis-edt.png",
  },
  {
    clintName: "Rojoli  Services",
    website: "https://www.rojoli.com/",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1720822247/werkix/clients-logos/rojoli-services_ajmn9x.png",
  },
  {
    clintName: "Salesforce",
    website: "https://www.salesforce.com",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1720832919/werkix/clients-logos/salesforce-edt.avif",
  },
  {
    clintName: "powani labs",
    website: "https://www.pewanilabs.com/",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1720822247/werkix/clients-logos/pewani_vbs97p.png",
  },
  {
    clintName: "Fieldscope International",
    website: "https://fieldscopeint.com/",
    logo: "https://res.cloudinary.com/dhbe6wtod/image/upload/v1720831640/werkix/clients-logos/fsi_nkycuv.png",
  },
];

export default logos;
