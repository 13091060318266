import React from "react";
import "./blogCategory.styles.css";
import DoubleArrowRight from "../../../svg/doubleArrowRight";

const BlogCategory = () => {
  return (
    <div class="category-container">
      <div className="category-highlighter"></div>
      <h2 class="blog-card-title">Category</h2>
      <div className="blog-categories">
        <ul>
          <li>
            <a href="#">
              <DoubleArrowRight />
              Web development
            </a>
            <span class="count">(12)</span>
          </li>
          <li>
            <a href="#">
              <DoubleArrowRight />
              Business Advice
            </a>
            <span class="count">(10)</span>
          </li>
          <li>
            <a href="#">
              <DoubleArrowRight />
              website design
            </a>
            <span class="count">(14)</span>
          </li>
          <li>
            <a href="#">
              <DoubleArrowRight />
              Ui/UX design
            </a>
            <span class="count">(03)</span>
          </li>
          <li class="selected">
            <a href="#">
              <DoubleArrowRight />
              Web ware framing
            </a>
            <span class="count">(02)</span>
          </li>
          <li>
            <a href="#">
              <DoubleArrowRight />
              Finished up
            </a>
            <span class="count">(32)</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogCategory;
