const projects = [
  {
    title: "A Case Study Of Sandram At Rojoli Services Inc",
    imageUrl:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1718628311/werkix/home/DSC02902_b672cs.jpg",
  },
  {
    title: "Manage Heroku Apps & Teams",
    imageUrl:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1719420026/werkix/about-main/about3_rccfnk.jpg",
  },
  {
    title: "Manage Heroku Apps & Teams",
    imageUrl:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1718628320/werkix/home/DSC02924-Edit_tyyld4.jpg",
  },
];

export default projects;
