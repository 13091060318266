import React, { useEffect, useRef } from "react";
import "./serviceHomeCard.styles.css";
import { motion, useInView, useAnimation, delay } from "framer-motion";

const ServiceHomeCard = ({ serviceIcon, serviceName, serviceDescription }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 1.5 }}
      ref={ref}
      className="service-card"
    >
      <div className="card-contents">
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 2.5 }}
          ref={ref}
          className="service-icons"
        >
          {serviceIcon}
        </motion.div>
        <motion.div animate={{ y: 0 }} className="subheading">
          {serviceName}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ServiceHomeCard;
