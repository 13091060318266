import React from "react";
import "./blogTags.styles.css";

const BlogTags = () => {
  return (
    <div class="tags-container">
      <div className="tags-blog-highlighter"></div>
      <h2 class="tag-title">Tags</h2>
      <div class="tag-container">
        <div class="tag">Interiour</div>
        <div class="tag">Graphics</div>
        <div class="tag selected">Design</div>
        <div class="tag">All Project</div>
        <div class="tag">Wareframing</div>
      </div>
    </div>
  );
};

export default BlogTags;
