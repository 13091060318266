import Nav from "../nav/nav.component";
import "./pageHeader.styles.css";
import React from "react";

const PageHeader = ({ pageName }) => {
  return (
    <div className="page">
      <Nav page={pageName} />

      <div className="page-header-bg">
        <div className="page-header-overlay">
          <div className="page-heading">{pageName}</div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
