import React, { useState } from "react";
import "./mainNav.styles.css";
import { Link, NavLink } from "react-router-dom";

const Nav = ({ page }) => {
  return (
    <div className="navv">
      <nav class="container">
        <ul class="nav-listt">
          <li class="nav-itemm">
            <Link
              to={"/"}
              href="#"
              className={`nav-linkk ${page == "home" ? "active" : ""}`}
            >
              Home
            </Link>
          </li>
          <li class="nav-itemm">
            <Link
              to={"/about"}
              href="#"
              className={`nav-linkk ${page == "about" ? "active" : ""}`}
            >
              About
            </Link>
          </li>
          <li class="nav-itemm">
            <Link
              to={"/ourtalents"}
              href="#"
              className={`nav-linkk ${page == "our talents" ? "active" : ""}`}
            >
              Our Talents
            </Link>
          </li>
          <li class="nav-itemm">
            <Link
              to={"/clients"}
              href="#"
              className={`nav-linkk ${page == "clients" ? "active" : ""}`}
            >
              Clients
            </Link>
          </li>
          <li class="nav-itemm">
            <Link
              to={"/blog"}
              href="#"
              className={`nav-linkk ${page == "blog" ? "active" : ""}`}
            >
              What's New
            </Link>
          </li>
          <li class="nav-itemm">
            <Link
              to={"/contact"}
              href="#"
              className={`nav-linkk ${page == "contact" ? "active" : ""}`}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
