import React from "react";

const AttachedFileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="attached-file"
    >
      <defs>
        <linearGradient
          id="a"
          x1="19.652"
          x2="2.832"
          y1="21.168"
          y2="4.348"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#66fa93"></stop>
          <stop offset=".117" stop-color="#66fa93"></stop>
          <stop offset=".503" stop-color="#66fa93"></stop>
          <stop offset=".811" stop-color="#66fa93"></stop>
          <stop offset="1" stop-color="#66fa93"></stop>
        </linearGradient>
      </defs>
      <g>
        <path
          fill="url(#a)"
          d="M21,12c0,2.06-.13,4.49-.51,7.19-.25,1.73-1.58,3.11-3.3,3.39-1.52,.25-3.27,.42-5.19,.42-1.44,0-3.2-.1-5.19-.42-1.71-.27-3.04-1.65-3.29-3.37-.31-2.12-.52-4.55-.52-7.21,0-2.06,.13-4.49,.51-7.19,.25-1.73,1.58-3.11,3.3-3.39,1.52-.25,3.27-.42,5.19-.42,.67,0,1.41,.02,2.2,.07,.27,.02,.54,.07,.8,.14V6c0,1.1,.9,2,2,2h3.84c.1,1.26,.16,2.6,.16,4Z"
        ></path>
      </g>
      <g>
        <g>
          <path
            fill="#fff"
            d="M21.8081,7.562c-.1006-1.1675-.6104-2.2681-1.4346-3.0991l-2.8892-2.9141c-.8589-.8667-2.0005-1.3892-3.2144-1.4717-2.5317-.1738-5.1123-.0527-7.6206,.353-2.1523,.3477-3.8101,2.0508-4.1245,4.2373-.3481,2.4209-.5249,4.8877-.5249,7.3325,0,2.4531,.1777,4.9277,.5283,7.356,.3135,2.1733,1.9722,3.8672,4.1265,4.2148,1.7627,.2847,3.561,.4292,5.3452,.4292,1.7871,0,3.5869-.145,5.3506-.4302,2.1523-.3477,3.8101-2.0508,4.1245-4.2373,.3481-2.4209,.5249-4.8877,.5249-7.3325,0-1.4775-.0645-2.9707-.1919-4.438Zm-2.8545-1.6909c.3165,.3193,.5455,.7089,.6928,1.1289h-2.6464c-.5513,0-1-.4487-1-1V2.9028c.0204,.0195,.0445,.0341,.0645,.0543l2.8892,2.9141Zm.542,13.1763c-.1895,1.3164-1.1797,2.3403-2.4644,2.5479-3.314,.5366-6.7397,.5376-10.0576,.001-1.2866-.2075-2.2778-1.2227-2.4658-2.5259-.3369-2.334-.5078-4.7129-.5078-7.0703,0-2.3496,.1699-4.7207,.5044-7.0474,.1895-1.3164,1.1797-2.3403,2.4644-2.5479,1.6582-.2686,3.3511-.4048,5.0312-.4048,.6653,0,1.3367,.0256,2,.0677v3.9323c0,1.6543,1.3457,3,3,3h2.8978c.0605,.9991,.1022,2.0021,.1022,3,0,2.3496-.1699,4.7207-.5044,7.0474Z"
          ></path>
          <path
            fill="#fff"
            d="M15,9.5c-.5522,0-1,.4478-1,1v6c0,1.103-.897,2-2,2s-2-.897-2-2v-5c0-.2759,.2241-.5,.5-.5s.5,.2241,.5,.5v5c0,.5522,.4478,1,1,1s1-.4478,1-1v-5c0-1.3784-1.1216-2.5-2.5-2.5s-2.5,1.1216-2.5,2.5v5c0,2.2056,1.7944,4,4,4s4-1.7944,4-4v-6c0-.5522-.4478-1-1-1Z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default AttachedFileIcon;
