import React, { useState } from "react";
import "./bannerNav.styles.css";
import { Link } from "react-router-dom";

const BannerNav = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  // Handle the click event to set the active item
  const handleItemClick = (index, sectionId) => {
    setActiveItem(index);
    setDropdownVisible(false);

    // Scroll to the specific section using the section ID
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div className="banner-container">
      <nav class={`nav`}>
        <Link to={"/"} class="logo" href="#">
          <img
            src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1728482531/werkix/logos/high%20resolution%20logos/werkix_new_logo01_oqznwr.png"
            alt="werkix logo"
          />
        </Link>
        <ul class="nav-list nav-list-primary">
          <li class="nav-item">
            <Link
              to={"/"}
              href="#"
              className={`nav-link`}
              onMouseEnter={handleDropdown}
              onMouseLeave={handleDropdown}
            >
              Home <span className="dropdown-arrow">&#9662;</span>
              {dropdownVisible && (
                <ul className="dropdown">
                  {[
                    { name: "About Company", sectionId: "about-company" },
                    { name: "Our Service", sectionId: "our-service" },
                    { name: "Case Studies", sectionId: "case-studies" },
                    { name: "Why Werkix", sectionId: "why-werkix" },
                    { name: "Meet Our Team", sectionId: "meet-our-team" },
                    {
                      name: "Talent Testimonies",
                      sectionId: "talent-testimonies",
                    },
                    {
                      name: "Clients Testimonials",
                      sectionId: "clients-testimonials",
                    },
                    { name: "Blog & Articles", sectionId: "blogs-articles" },
                  ].map((item, index) => (
                    <li
                      key={index}
                      className={`dropdown-item ${
                        activeItem === index ? "active" : ""
                      }`}
                      onClick={() => handleItemClick(index, item.sectionId)}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              )}
            </Link>
          </li>
          <li class="nav-item">
            <Link to={"/about"} href="#" class="nav-link">
              About
            </Link>
          </li>
          <li class="nav-item">
            <Link to={"/ourtalents"} href="#" class="nav-link">
              Our Talents
            </Link>
          </li>
          <li class="nav-item">
            <Link to={"/clients"} href="#" class="nav-link">
              Clients
            </Link>
          </li>
          <li class="nav-item">
            <Link to={"/blog"} href="#" class="nav-link ">
              What's New
            </Link>
          </li>
          <li class="nav-item">
            <Link to={"/contact"} href="#" class="nav-link ">
              Contact
            </Link>
          </li>
        </ul>
        <li class="nav-item nav-link-button">
          <Link to={"/ourtalents"} href="#" class="nav-link ">
            Join Us
          </Link>
        </li>
      </nav>
    </div>
  );
};

export default BannerNav;
