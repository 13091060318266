import React from "react";

const BurgerMenu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="#fff"
      viewBox="0 0 128 128"
      id="burger-menu"
    >
      <path
        stroke="#fff"
        stroke-linecap="round"
        stroke-width="7"
        d="M21 65H107M21 96H107M21 33L107 33"
      ></path>
    </svg>
  );
};

export default BurgerMenu;
