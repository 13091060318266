import React, { useEffect, useRef } from "react";
import "./banner.styles.css";
import CallIcon from "../../svg/call";
import ArrowRight from "../../svg/arrowRight";
import BannerNav from "../banner-nav/bannerNav.component";
import RightSmGreenVectors from "../../vectors/banner/right-tiny";
import BottomLeftVectors from "../../vectors/banner/botton-left";
import XVectorComponent from "../XVector/xVector.component";
import { motion, useInView, useAnimation } from "framer-motion";

const Banner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div className="banner">
      <div className="banner-wrap ">
        <BannerNav />
        <div alt="" className="banner-image"></div>
        <div className="banner-content container">
          <span className="mission">Mission</span>
          <motion.span
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 1.5 }}
            ref={ref}
            className="empower"
          >
            Empowering African tech talent
          </motion.span>
          <motion.span
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 1.5 }}
            ref={ref}
            className="content-text"
          >
            At Werkix, we are fueled by a passion to reshape the tech industry
            by seamlessly connecting talented individuals in Africa with
            abundant global market opportunities.
          </motion.span>
          <motion.span
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1.5, delay: 2 }}
            className="phone"
          >
            <CallIcon />
            +265 888 007 888 <ArrowRight />
          </motion.span>
        </div>
        <span className="bottom-right-vectors">
          <RightSmGreenVectors />
        </span>
        {/* <span className="exes">
          <Exes />
        </span> */}
        <XVectorComponent />

        <span className="bottom-left-vectors">
          <BottomLeftVectors />
        </span>
      </div>
    </div>
  );
};

export default Banner;
