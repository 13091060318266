import React from "react";

const PlayBtn = () => {
  return (
    <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26 14L0.5 27.8564L0.5 0.143594L26 14Z" fill="white" />
    </svg>
  );
};

export default PlayBtn;
