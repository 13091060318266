import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LinkedInLogin.css";

function LinkedInLogin() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get("http://localhost:5001/posts", {
          withCredentials: true,
        });
        setPosts(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="linkedin-container">
      <h1>LinkedIn Posts</h1>
      {posts.map((post, index) => (
        <div key={index} className="post">
          <h2>Post</h2>
          <p>{post.text}</p>
          <p>
            <strong>Created At:</strong>{" "}
            {new Date(post.createdAt).toLocaleString()}
          </p>

          {post.media.length > 0 && (
            <div className="media">
              {post.media.map((mediaItem, mediaIndex) => (
                <div key={mediaIndex} className="media-item">
                  {mediaItem.entityType === "IMAGE" && (
                    <img src={mediaItem.entityLocation} alt="Post Media" />
                  )}
                  {mediaItem.entityType === "VIDEO" && (
                    <video controls>
                      <source src={mediaItem.entityLocation} type="video/mp4" />
                    </video>
                  )}
                </div>
              ))}
            </div>
          )}

          <h3>Comments ({post.totalComments})</h3>
          {post.comments.map((comment, commentIndex) => (
            <div key={commentIndex} className="comment">
              <p>
                <strong>{comment.author}</strong> -{" "}
                {new Date(comment.createdAt).toLocaleString()}
              </p>
              <p>{comment.text}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default LinkedInLogin;
