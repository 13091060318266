import React from "react";

const LinkedInIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
    >
      <path
        d="M12.001 9.55C12.918 8.613 14.112 8 15.501 8C16.9597 8 18.3586 8.57946 19.3901 9.61091C20.4215 10.6424 21.001 12.0413 21.001 13.5V21H19.001V13.5C19.001 12.5717 18.6322 11.6815 17.9759 11.0251C17.3195 10.3687 16.4292 10 15.501 10C14.5727 10 13.6825 10.3687 13.0261 11.0251C12.3697 11.6815 12.001 12.5717 12.001 13.5V21H10.001V8.5H12.001V9.55ZM5.00098 6.5C4.60315 6.5 4.22162 6.34196 3.94032 6.06066C3.65901 5.77936 3.50098 5.39782 3.50098 5C3.50098 4.60218 3.65901 4.22064 3.94032 3.93934C4.22162 3.65804 4.60315 3.5 5.00098 3.5C5.3988 3.5 5.78033 3.65804 6.06164 3.93934C6.34294 4.22064 6.50098 4.60218 6.50098 5C6.50098 5.39782 6.34294 5.77936 6.06164 6.06066C5.78033 6.34196 5.3988 6.5 5.00098 6.5ZM4.00098 8.5H6.00098V21H4.00098V8.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default LinkedInIcon;
