import React from "react";

const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="67"
      viewBox="0 0 65 67"
      fill="none"
    >
      <path
        d="M32.574 66.5038C22.7676 66.5038 14.7872 64.9054 9.27838 62.2858C3.75492 59.6592 0.832031 56.0733 0.832031 52.1288C0.832031 48.4049 3.48354 45.141 7.7623 42.659C11.8976 40.2601 17.4516 38.6587 23.226 38.1341V43.9227C19.4015 44.2867 15.6264 45.1628 12.7565 46.4284C11.2656 47.0858 9.9932 47.8596 9.08611 48.7426C8.17753 49.6271 7.60203 50.6564 7.60203 51.8068C7.60203 53.2433 8.34305 54.5103 9.59589 55.5803C10.8438 56.6461 12.6327 57.5484 14.8331 58.2803C19.2377 59.7452 25.4141 60.5668 32.574 60.5668C39.724 60.5668 45.9003 59.7381 50.3073 58.2697C52.509 57.5361 54.2994 56.633 55.5487 55.5689C56.8025 54.501 57.546 53.2375 57.546 51.8068C57.546 50.6564 56.9705 49.6271 56.062 48.7426C55.1549 47.8596 53.8825 47.0858 52.3915 46.4284C49.5216 45.1628 45.7466 44.2867 41.922 43.9227V38.1341C47.6998 38.6586 53.2538 40.2601 57.3884 42.6589C61.6661 45.1409 64.316 48.4048 64.316 52.1288C64.316 56.0734 61.3947 59.6593 55.8723 62.2858C50.3644 64.9054 42.384 66.5038 32.574 66.5038Z"
        stroke="#67FB94"
      />
      <path
        opacity="0.4"
        d="M35.9371 23.6577L35.2359 23.8772V24.612V42.686C35.2359 45.7756 34.7067 48.5576 34.0211 50.5415C33.6766 51.5382 33.3062 52.2942 32.971 52.7793C32.8349 52.9762 32.7195 53.1066 32.6322 53.1872C32.5441 53.1057 32.4277 52.9739 32.2904 52.7752C31.9529 52.287 31.5795 51.5276 31.2321 50.529C30.5404 48.5411 30.0049 45.7597 30.0049 42.686V24.584V23.8505L29.3052 23.6302C24.6709 22.1711 21.2969 17.7805 21.2969 12.579C21.2969 6.20058 26.3726 1 32.6309 1C38.9223 1 43.9649 6.19871 43.9649 12.579C43.9649 17.783 40.5957 22.1991 35.9371 23.6577ZM32.5157 53.2766C32.5157 53.2765 32.5168 53.2759 32.5191 53.275C32.5168 53.2761 32.5157 53.2766 32.5157 53.2766ZM32.7459 53.2756C32.7483 53.2766 32.7495 53.2773 32.7494 53.2773C32.7494 53.2774 32.7482 53.2769 32.7459 53.2756ZM29.1449 3.718C26.3473 3.718 23.9169 6.13102 23.9169 9.009C23.9169 11.8463 26.3348 14.328 29.1449 14.328C32.0516 14.328 34.3729 11.8333 34.3729 9.009C34.3729 6.14392 32.0392 3.718 29.1449 3.718Z"
        stroke="#67FB94"
        stroke-width="2"
      />
    </svg>
  );
};

export default LocationIcon;
