import React, { useEffect, useState } from "react";
import "./blog.styles.css";
import Arrow from "../../svg/arrow";
import ClockIcon from "../../svg/clockIcon";
import CommentIcon from "../../svg/commentIcon";
import { Link } from "react-router-dom";
import axios from "axios";

const Blog = ({
  heading,
  description,
  image,
  date,
  blogger,
  postion,
  comments = 10,
}) => {
  return (
    <div className="blog">
      <article>
        <div class="article-container">
          <div className="article-img">
            {" "}
            <img src={image} alt="article-img" />
          </div>
          <div className="article-details">
            <div className="date-comment">
              <span className="blog-date">
                <ClockIcon />
                {date}
              </span>
              <span className="blog-comment">
                {/* <CommentIcon /> Comments ({comments}) */}
              </span>
            </div>
            <header>
              <h2 tabindex="0" class="blog-heading">
                {heading}
              </h2>
            </header>

            <p className="blog-desc">{description}</p>

            <div class="blog-redirect">
              <div className="blogger-details">
                <div className="profile-img">
                  <img
                    src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1719163235/werkix/logos/logo-alt_axx0af.jpg"
                    alt=""
                  />
                </div>
                <div className="name-position">
                  {/* <span className="name">{blogger}</span> */}
                  <span className="position">Werkix</span>
                </div>
              </div>
              <a
                href="https://www.linkedin.com/company/88634432/admin/page-posts/published/"
                target="_blank"
                rel="nooper noreferrer"
                className="redirect-btn"
              >
                Read More <Arrow />
              </a>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Blog;
