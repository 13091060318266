import React from "react";

const CallIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5664 15.2571C18.9421 14.7336 15.2807 12.415 14.6721 12.5214C14.3864 12.5721 14.1679 12.8157 13.5829 13.5136C13.3123 13.8571 13.0152 14.1788 12.6943 14.4757C12.1063 14.3337 11.5371 14.1227 10.9986 13.8471C8.88663 12.8189 7.18045 11.1122 6.15286 9C5.87731 8.46148 5.66633 7.8923 5.52429 7.30429C5.82124 6.98336 6.14294 6.68625 6.48643 6.41571C7.18357 5.83071 7.42786 5.61357 7.47857 5.32643C7.585 4.71643 5.26429 1.05643 4.74286 0.432143C4.52429 0.173571 4.32571 0 4.07143 0C3.33429 0 0 4.12286 0 4.65714C0 4.70071 0.0714285 8.99286 5.49214 14.5079C11.0071 19.9286 15.2993 20 15.3429 20C15.8771 20 20 16.6657 20 15.9286C20 15.6743 19.8264 15.4757 19.5664 15.2571ZM15 9.28571H16.4286C16.4269 7.77071 15.8243 6.31825 14.753 5.24698C13.6817 4.17572 12.2293 3.57313 10.7143 3.57143V5C11.8506 5.00113 12.94 5.45303 13.7435 6.25651C14.547 7.05999 14.9989 8.14942 15 9.28571Z"
        fill="#66FA93"
      />
      <path
        d="M18.571 9.28571H19.9996C19.9967 6.82386 19.0175 4.46365 17.2767 2.72285C15.5359 0.98206 13.1757 0.0028357 10.7139 0V1.42857C12.797 1.43103 14.794 2.25962 16.267 3.73259C17.74 5.20556 18.5686 7.20262 18.571 9.28571Z"
        fill="#66FA93"
      />
    </svg>
  );
};

export default CallIcon;
