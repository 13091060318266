import React, { useEffect, useRef } from "react";
import "./aboutHome.styles.css";
import { motion, useInView, useAnimation } from "framer-motion";

const AboutHome = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 1.5 }}
      ref={ref}
      className="about-home"
      id="about-company"
    >
      <div className="about-home-wrap">
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 1.5 }}
          ref={ref}
          className="about-left"
        >
          <div className="images-wrap1">
            <img
              src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1718974870/werkix/home/tet_mjtevh.jpg"
              alt=""
              className="primary-img"
            />
          </div>
          <div className="images-wrap2">
            <img
              src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1718974871/werkix/home/mattHome_wzdozo.jpg"
              alt=""
              className="secondary-img"
            />
          </div>
        </motion.div>

        <motion.div
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 1.5, delay: 2 }}
          ref={ref}
          className="about-right"
        >
          <div className="home-title">About Company</div>
          <div className="home-heading">Who We Are</div>
          <div className="about-text">
            With decades of experience in internet connectivity, digital
            transformation, and software development, we have pinpointed a
            critical inefficiency in the utilization of tech talent. Many
            skilled professionals, despite their extensive education and
            training, are often found in unrelated roles due to the sluggish
            industry growth in their regions. <br />
            <br />
            The COVID-19 pandemic accelerated the global adoption of remote
            work; however, African tech talent was largely overlooked, grappling
            with a lack of exposure, resources, and opportunities to upskill.
            This is where Werkix steps in as an innovative partner and trusted
            advisor. We empower skilled individuals by linking them with US and
            European clients, nurturing not just employment, but career paths
            that are both challenging and fulfilling.
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default AboutHome;
