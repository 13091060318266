import { Link } from "react-router-dom";
import ClockIcon from "../../../svg/clockIcon";
import CommentIcon from "../../../svg/commentIcon";
import ArrowLeft from "../../../svg/ourserviceHome/arrowLeft";
import ArrowRightNofill from "../../../svg/ourserviceHome/arrowRightNofill";
import "./blobCard.styles.css";

import React from "react";

const BlogCard = ({ cardImage, title, date, first, last }) => {
  return (
    <Link to={"/blog"} className={`blog-card`}>
      <div className="card-wrap">
        <div
          className={`blog-picture ${
            first ? "radius-left" : last ? "radius-right" : ""
          }`}
        >
          <img src={cardImage} alt="" />
        </div>
        <div className="blog-card-details">
          <div className="blog-info">
            <span className="blog-title">{title}</span>
            <div className="date-comment">
              <span className="blog-date">
                <ClockIcon />
                {date}
              </span>
            </div>
          </div>

          <div className="blog-arrow">
            <ArrowRightNofill />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
