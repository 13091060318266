import React, { useEffect, useState } from "react";
import "./whatsnew.style.css";
import PageHeader from "../../components/pageHeader/pageHeader.component.jsx";
import Blog from "../../components/blog/blog.component.jsx";
import BlogCategory from "../../components/cards/blogCategory/blogCategory.component.jsx";
import PopularPosts from "../../components/cards/popularPosts/popularPosts.component.jsx";
import BlogTags from "../../components/cards/blogTags/blogTags.component.jsx";
import SadEmoj from "../../svg/sadEmoj.js";
import BounceLoader from "react-spinners/BounceLoader";

const WhatsNewPage = ({
  posts,
  loading,
  error,
  getPostDate,
  extractHeading,
  checkTextLength,
}) => {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [postsPerPage] = useState(5); // Number of posts per page

  // Get current posts for the current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts?.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const totalPages = Math.ceil(posts?.length / postsPerPage);

  return (
    <div className="blog-page">
      <PageHeader pageName={"blog"} />
      <div className="blog-container">
        <div className="main-blog">
          {error ? (
            <div className="posts-error">
              <SadEmoj />
              {error}
            </div>
          ) : loading ? (
            <div className="loading-state">
              <BounceLoader
                color="#67fb94"
                loading={loading}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : currentPosts.length > 0 ? (
            <>
              {" "}
              {currentPosts.map((post, index) => (
                <Blog
                  key={index}
                  image={
                    post.content?.contentEntities?.length > 0
                      ? post.content?.contentEntities[0]?.entityLocation
                      : "https://res.cloudinary.com/dhbe6wtod/image/upload/v1721319960/werkix/blog/blog01_zoooab.jpg"
                  }
                  heading={extractHeading(post.text?.text, 80)}
                  description={checkTextLength(post?.text?.text, 600)}
                  date={getPostDate(post.created.time)}
                />
              ))}
            </>
          ) : (
            <p className="no-posts">No posts found.</p>
          )}
        </div>

        <div className="blog-right">
          {/* <BlogSearch /> */}
          <BlogCategory />
          <PopularPosts
            posts={posts}
            getPostDate={getPostDate}
            extractHeading={extractHeading}
            loading={loading}
            error={error}
          />
          <BlogTags />
        </div>
        {/*Pagination controls */}
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <div
              key={index}
              className={`page-number ${
                currentPage === index + 1 ? "selected" : ""
              }`}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatsNewPage;
