import React from "react";

const CodeAssessmentIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill={fill}
    >
      <g clip-path="url(#clip0_275_1711)">
        <path
          d="M25.1562 61.25H2.1875V8.75H33.9062C34.1963 8.75 34.4745 8.63477 34.6796 8.42965C34.8848 8.22453 35 7.94633 35 7.65625C35 7.36617 34.8848 7.08797 34.6796 6.88285C34.4745 6.67773 34.1963 6.5625 33.9062 6.5625H1.09375C0.803669 6.5625 0.52547 6.67773 0.320352 6.88285C0.115234 7.08797 0 7.36617 0 7.65625L0 62.3438C0 62.6338 0.115234 62.912 0.320352 63.1171C0.52547 63.3223 0.803669 63.4375 1.09375 63.4375H25.1562C25.4463 63.4375 25.7245 63.3223 25.9296 63.1171C26.1348 62.912 26.25 62.6338 26.25 62.3438C26.25 62.0537 26.1348 61.7755 25.9296 61.5704C25.7245 61.3652 25.4463 61.25 25.1562 61.25Z"
          fill={fill}
        />
        <path
          d="M68.9062 6.5625H44.8438C44.5537 6.5625 44.2755 6.67773 44.0704 6.88285C43.8652 7.08797 43.75 7.36617 43.75 7.65625C43.75 7.94633 43.8652 8.22453 44.0704 8.42965C44.2755 8.63477 44.5537 8.75 44.8438 8.75H67.8125V61.25H36.0938C35.8037 61.25 35.5255 61.3652 35.3204 61.5704C35.1152 61.7755 35 62.0537 35 62.3438C35 62.6338 35.1152 62.912 35.3204 63.1171C35.5255 63.3223 35.8037 63.4375 36.0938 63.4375H68.9062C69.1963 63.4375 69.4745 63.3223 69.6796 63.1171C69.8848 62.912 70 62.6338 70 62.3438V7.65625C70 7.36617 69.8848 7.08797 69.6796 6.88285C69.4745 6.67773 69.1963 6.5625 68.9062 6.5625Z"
          fill={fill}
        />
        <path
          d="M50.5891 44.4765C50.7818 44.6931 51.0526 44.8243 51.342 44.8413C51.6313 44.8583 51.9156 44.7598 52.1324 44.5673L61.9762 35.8173C62.0925 35.7147 62.1857 35.5884 62.2496 35.447C62.3134 35.3056 62.3465 35.1522 62.3465 34.997C62.3465 34.8418 62.3134 34.6884 62.2496 34.547C62.1857 34.4055 62.0925 34.2793 61.9762 34.1767L52.1324 25.4267C51.9148 25.234 51.6297 25.1357 51.3396 25.1534C51.0496 25.171 50.7785 25.3032 50.5858 25.5207C50.3932 25.7383 50.2949 26.0235 50.3126 26.3135C50.3302 26.6035 50.4623 26.8747 50.6799 27.0673L59.6093 35.0003L50.6854 42.9332C50.468 43.1252 50.3358 43.3956 50.3178 43.6849C50.2997 43.9743 50.3973 44.2591 50.5891 44.4765Z"
          fill={fill}
        />
        <path
          d="M19.4119 25.523C19.3164 25.4155 19.2007 25.3278 19.0713 25.265C18.942 25.2022 18.8015 25.1656 18.658 25.1572C18.5144 25.1487 18.3706 25.1687 18.2348 25.2159C18.099 25.2631 17.9738 25.3366 17.8664 25.4322L8.02265 34.1822C7.90626 34.2849 7.81305 34.4111 7.74921 34.5525C7.68536 34.694 7.65234 34.8474 7.65234 35.0025C7.65234 35.1577 7.68536 35.3111 7.74921 35.4526C7.81305 35.594 7.90626 35.7202 8.02265 35.8229L17.8664 44.5729C18.0831 44.7658 18.3675 44.8647 18.6572 44.8479C18.9468 44.831 19.2179 44.6999 19.4108 44.4832C19.6037 44.2665 19.7026 43.982 19.6858 43.6924C19.669 43.4028 19.5378 43.1317 19.3211 42.9388L10.3961 35.0004L19.3211 27.0685C19.4286 26.973 19.5163 26.8573 19.5791 26.728C19.6419 26.5986 19.6785 26.4581 19.6869 26.3146C19.6954 26.171 19.6754 26.0272 19.6282 25.8914C19.581 25.7556 19.5075 25.6304 19.4119 25.523Z"
          fill={fill}
        />
        <path
          d="M39.193 16.4321C39.0526 16.3998 38.9071 16.3956 38.7649 16.4197C38.6228 16.4438 38.4868 16.4958 38.3648 16.5726C38.2429 16.6495 38.1373 16.7497 38.0542 16.8675C37.9711 16.9853 37.9121 17.1183 37.8805 17.259L29.9771 52.259C29.9448 52.3994 29.9405 52.5449 29.9646 52.6869C29.9886 52.829 30.0405 52.9649 30.1172 53.0869C30.1939 53.2089 30.294 53.3144 30.4117 53.3976C30.5294 53.4808 30.6623 53.5399 30.8029 53.5715C30.8823 53.5888 30.9633 53.5976 31.0446 53.5978C31.2926 53.5977 31.5333 53.5133 31.727 53.3585C31.9208 53.2037 32.0562 52.9876 32.111 52.7457L40.0145 17.7457C40.047 17.6055 40.0516 17.4603 40.0279 17.3183C40.0042 17.1764 39.9527 17.0405 39.8764 16.9184C39.8001 16.7964 39.7005 16.6906 39.5832 16.6072C39.4659 16.5237 39.3333 16.4642 39.193 16.4321Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_275_1711">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CodeAssessmentIcon;
