import React, { useEffect, useState } from "react";
import "./clients.style.css";
import PageHeader from "../../components/pageHeader/pageHeader.component.jsx";
import PinterestPJIcon from "../../svg/clients/socials/pinterestIcon.js";
import XIcon from "../../svg/clients/socials/xIcon.js";
import FbPJIcon from "../../svg/clients/socials/fbIcon.js";
import ProjectCard from "../../components/cards/projectCard/projectCard.component.jsx";
import SliderButtonLeft from "../../components/slider-button/sliderButtonLeft.component.jsx";
import SliderButtonRight from "../../components/slider-button/sliderButtonRight.component.jsx";
import ClientLogosCarousel from "../../components/clientsSlide/ClientLogosCarousel.component.jsx";
import logos from "../../data/logos";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import projects from "../../data/projects.js";
import CaseStudyCard from "../../components/cards/caseStudyCard/caseStudyCard.component.jsx";
import caseStudies from "../../data/caseStudies.js";
import { shuffleArray } from "../../helpers/shuffleArray.js";
import ArrowButton from "../../components/buttons/arrowButton.component.jsx";

const ClientsPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? projects.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === projects.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const getRandomCaseStudies = (caseStudies) => {
    const shuffledStudies = shuffleArray(caseStudies);
    return shuffledStudies.slice(0, 3);
  };

  const [randomStudies, setRandomStudies] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  // const caseStudyId = parseInt(id, 10) || 1;
  const caseStudyId = id ? parseInt(id, 10) : 1;

  const activeCasestudy = caseStudies.find(
    (casestudy) => casestudy.id === caseStudyId
  );

  useEffect(() => {
    if (!activeCasestudy && id) {
      navigate("/clients/1"); // Redirect to default ID 1
    }
    setRandomStudies(getRandomCaseStudies(caseStudies));
  }, [activeCasestudy, id, navigate]);

  // If no id is provided, redirect to id 1 using Navigate component
  if (!id) {
    return <Navigate to="/casestudies/1" />;
  }

  // If the case study is not found, display an error or redirect
  if (!activeCasestudy) {
    return <p>Case study not found. Redirecting...</p>;
  }
  return (
    <div className="clients-page">
      <PageHeader pageName={"clients"} />
      <div className="clients-container">
        <div className="home-title clients-subheading">For our clients</div>
        <div className="home-heading clients-heading">For Our clients</div>
        <div className="project">
          <CaseStudyCard
            image={activeCasestudy.image}
            name={activeCasestudy.name}
            client={activeCasestudy.client}
            status={activeCasestudy.status}
            summary={activeCasestudy.summary}
            challenge={activeCasestudy.challenge}
            results={activeCasestudy.results}
            solution={activeCasestudy.solution}
            x={activeCasestudy.socialLinks.x}
            meta={activeCasestudy.socialLinks.meta}
            pinterest={activeCasestudy.socialLinks.pinterest}
          />
          <ClientLogosCarousel logos={logos} />
          <div className="similar-projects">
            <div className="home-title">More Projects</div>
            <div className="home-heading"> Similar Projects</div>

            <div className="similar-projects-container">
              <div onClick={goToPrevious} className="next-btn btn-slider">
                {" "}
                <ArrowButton className="service-icon-left" />
              </div>
              <div className="more-projects">
                {randomStudies.map((casestudy, index) => (
                  <Link
                    to={`/clients/${casestudy.id}`}
                    className={`project-card ${
                      index === currentIndex ? "active" : ""
                    }`}
                    key={index}
                  >
                    <img src={casestudy.image} alt="project-img" />
                    <div className="project-overlay">{casestudy.name}</div>
                  </Link>
                ))}
              </div>
              <div onClick={goToNext} className="previous-btn btn-slider">
                {" "}
                <ArrowButton right className="service-icon-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsPage;
