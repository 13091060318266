import React from "react";
import "./xVectorComponent.styles.css";
import XVector from "../../svg/contactPage/xVector";

const XVectorComponent = () => {
  return (
    <div className="ex-vectors">
      <div className="vector-element1">
        <XVector />
        <XVector />
        <XVector />
        <XVector />
        <XVector />
        <XVector />
      </div>
      <div className="vector-element2">
        <XVector />
        <XVector />
        <XVector />
        <XVector />
        <XVector />
        <XVector />
      </div>
    </div>
  );
};

export default XVectorComponent;
