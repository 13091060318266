import React from "react";
import Quotes from "../../svg/quotes";
import "./dividerQuote.styles.css";

const DividerQuote = () => {
  return (
    <div className="divider-container">
      <div className="divider-line"></div>
      <div className="quote">
        <Quotes />
      </div>
    </div>
  );
};

export default DividerQuote;
