import React from "react";

const RightSmGreenVectors = () => {
  return (
    <svg
      width="183"
      height="50"
      viewBox="0 0 183 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M171.603 49.4446H182.579L153.969 0H142.992L171.603 49.4446Z"
        fill="#66FA93"
      />
      <path
        d="M142.992 49.4446H153.969L125.359 0H114.412L142.992 49.4446Z"
        fill="#66FA93"
      />
      <path
        d="M114.412 49.4446H125.359L96.778 0H85.8013L114.412 49.4446Z"
        fill="#66FA93"
      />
      <path
        d="M85.8014 49.4446H96.778L68.1677 0H57.191L85.8014 49.4446Z"
        fill="#66FA93"
      />
      <path
        d="M57.191 49.4446H68.1677L39.5574 0H28.5807L57.191 49.4446Z"
        fill="#66FA93"
      />
      <path
        d="M28.5807 49.4446H39.5574L10.9471 0H0L28.5807 49.4446Z"
        fill="#66FA93"
      />
    </svg>
  );
};

export default RightSmGreenVectors;
