import React, { useState } from "react";
import "./SlidingText.styles.css";

const SlidingText = () => {
  const [paused, setPaused] = useState(false);

  const handleMouseEnter = () => {
    setPaused(true);
  };

  const handleMouseLeave = () => {
    setPaused(false);
  };

  return (
    <div className="sliding-text-container">
      <div
        className={`sliding-text ${paused ? "paused" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span>TRAIN, MONITOR, INCUBATE, </span>
        <span>TRAIN, MONITOR, INCUBATE, </span>
        <span>TRAIN, MONITOR, INCUBATE, </span>
        <span>TRAIN, MONITOR, INCUBATE, </span>
      </div>
    </div>
  );
};

export default SlidingText;
