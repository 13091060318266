import React from "react";
import "./sliderButton.style.css";
import ArrowRightNofill from "../../svg/ourserviceHome/arrowRightNofill";
import ArrowLeft from "../../svg/ourserviceHome/arrowLeft";

const SliderButton = ({ direction, bg, fill }) => {
  return (
    <div
      className={`slider-btn ${
        direction === "right" ? "bg-primary btn-one" : "bg-secondary"
      }`}
    >
      {direction === "right" ? (
        <ArrowRightNofill fill="#161D27" />
      ) : (
        <ArrowLeft fill="#66fa93" />
      )}
    </div>
  );
};

export default SliderButton;
