import React from "react";
import "./teamMemberDetails.style.css";
import { useNavigate, useParams } from "react-router-dom";
import teamMembers from "../../data/teamMembers";
import CallUsIcon from "../../svg/callUs";
import PageHeader from "../../components/pageHeader/pageHeader.component";
import EmailIcon from "../../svg/EmailIcon";
import PinIcon from "../../svg/PinIcon";
import SliderButtonRight from "../../components/slider-button/sliderButtonRight.component";
import SliderButtonLeft from "../../components/slider-button/sliderButtonLeft.component";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TeamMemberCard from "../../components/cards/teamMember/teamMember.component";
import LinkedInIcon from "../../svg/socialIcons/linkedIn";

const TeamMemberDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const member = teamMembers.find((member) => member.id === parseInt(id)); // Find the member based on ID
  if (!member) {
    return <div className="no-member">Team member not found</div>;
  }

  if (member) {
    return (
      <>
        <PageHeader pageName={"Team Details"} />
        <div className="team-member-detail">
          <div className="detail-container">
            <div className="member-photo-detail">
              <img src={member.image} alt={member.name} />
            </div>
            <div className="member-info-detail">
              <h2 className="home-heading">{member.name}</h2>
              <p className="position home-title">{member.position}</p>
              <p className="description">{member.statement}</p>
              <div className="contact-info">
                <h3>Let's connect</h3>
                <ul>
                  <li>
                    <div className="contact-icon">
                      <EmailIcon />
                    </div>{" "}
                    <div className="contact-method">
                      <strong>Email: </strong>
                      {member.contact.email}
                    </div>
                  </li>
                  <li>
                    <div className="contact-icon">
                      <PinIcon />
                    </div>{" "}
                    <div className="contact-method">
                      <strong>Location: </strong>
                      {member.contact.location}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="profile">
            {teamMembers.map((member) => (
              <TeamMemberCard
                id={member.id}
                mainCard
                image={member.image}
                name={member.name}
                position={member.position}
                key={member.id}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
};

export default TeamMemberDetails;
