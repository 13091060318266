import React from "react";

const XVector = ({ fill, stroke, strokeWidth, fillOpacity }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill={fill}
      stroke={stroke}
    >
      <path
        opacity="1"
        d="M9.96676 60.6776L30.6195 40.1199L51.2224 60.6776L61 50.9214L40.3971 30.3139L61 9.7562L51.2224 -4.27394e-07L30.6195 20.5577L9.96676 -2.23073e-06L0.189128 9.7562L20.792 30.3139L0.189127 50.9214L9.96676 60.6776Z"
        fill={fill}
        fill-opacity={fillOpacity}
        stroke={stroke}
        stroke-width={strokeWidth}
      />
    </svg>
  );
};

export default XVector;
