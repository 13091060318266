import React from "react";

const LeftTestimonialBg = ({ opacity }) => {
  return (
    <svg
      width="941"
      height="800"
      viewBox="0 0 941 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity} clip-path="url(#clip0_192_535)">
        <path
          d="M864.646 340.579L368.607 546.475L73.5159 483.593L527.154 265.676L926.445 612.444L173.292 302.794L293.817 128.109L444.681 414.384L368.607 546.475L322.341 364.088"
          stroke="#66FA93"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M566.35 464.4L527.154 265.677L444.681 414.384"
          stroke="#66FA93"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M296.626 140.467C303.448 138.916 307.722 132.127 306.17 125.302C304.619 118.477 297.83 114.202 291.008 115.752C284.185 117.303 279.912 124.093 281.463 130.918C283.014 137.743 289.803 142.018 296.626 140.467Z"
          fill="#27F7EA"
        />
        <path
          d="M867.455 352.937C874.278 351.386 878.551 344.596 877 337.771C875.448 330.947 868.66 326.671 861.837 328.222C855.014 329.773 850.741 336.563 852.293 343.388C853.844 350.213 860.633 354.488 867.455 352.937Z"
          fill="#27F7EA"
        />
        <path
          d="M447.33 426.042C454.153 424.491 458.426 417.701 456.875 410.876C455.324 404.051 448.535 399.776 441.712 401.327C434.89 402.878 430.617 409.667 432.168 416.492C433.719 423.317 440.508 427.592 447.33 426.042Z"
          fill="#27F7EA"
        />
        <path
          d="M79.2389 492.386C83.7873 491.352 86.6361 486.825 85.6019 482.275C84.5676 477.725 80.0419 474.875 75.4935 475.909C70.945 476.943 68.0962 481.47 69.1305 486.02C70.1648 490.569 74.6904 493.42 79.2389 492.386Z"
          fill="#27F7EA"
        />
        <path
          d="M705.439 529.127C709.988 528.093 712.837 523.566 711.802 519.017C710.768 514.467 706.242 511.616 701.694 512.65C697.145 513.684 694.297 518.211 695.331 522.761C696.365 527.311 700.891 530.161 705.439 529.127Z"
          fill="#27F7EA"
        />
        <path
          d="M765.839 553.648C770.387 552.615 773.236 548.088 772.202 543.538C771.168 538.988 766.642 536.138 762.094 537.172C757.545 538.206 754.696 542.732 755.731 547.282C756.765 551.832 761.291 554.682 765.839 553.648Z"
          fill="#27F7EA"
        />
        <path
          d="M928.318 620.683C932.866 619.649 935.715 615.122 934.681 610.572C933.646 606.022 929.121 603.172 924.572 604.206C920.024 605.24 917.175 609.767 918.209 614.316C919.244 618.866 923.769 621.717 928.318 620.683Z"
          fill="#27F7EA"
        />
        <path
          d="M529.027 273.915C533.575 272.881 536.424 268.355 535.39 263.805C534.355 259.255 529.83 256.405 525.281 257.438C520.733 258.472 517.884 262.999 518.918 267.549C519.953 272.099 524.478 274.949 529.027 273.915Z"
          fill="#27F7EA"
        />
        <path
          d="M468.339 300.706C472.887 299.672 475.736 295.146 474.702 290.596C473.667 286.046 469.142 283.196 464.593 284.229C460.045 285.263 457.196 289.79 458.23 294.34C459.265 298.89 463.79 301.74 468.339 300.706Z"
          fill="#27F7EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_192_535">
          <rect
            width="820.633"
            height="633.636"
            fill="white"
            transform="translate(0 181.902) rotate(-12.8066)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeftTestimonialBg;
