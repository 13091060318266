import React from "react";

const ExesVertical = () => {
  return (
    <svg
      width="153"
      height="568"
      viewBox="0 0 153 568"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M101.967 60.6776L122.62 40.1199L143.222 60.6776L153 50.9214L132.397 30.3139L153 9.7562L143.222 -4.27394e-07L122.62 20.5577L101.967 -2.23073e-06L92.1891 9.7562L112.792 30.3139L92.1891 50.9214L101.967 60.6776Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        d="M101.967 102.01L122.337 122.335L122.62 122.617L122.902 122.335L143.222 102.011L152.434 111.25L132.115 131.525L131.831 131.808L132.115 132.091L152.434 152.366L143.222 161.557L122.902 141.281L122.62 141L122.337 141.281L101.967 161.557L92.7554 152.366L113.075 132.091L113.358 131.808L113.075 131.525L92.7547 111.25L101.967 102.01Z"
        stroke="#66FA93"
        stroke-width="0.8"
      />
      <path
        opacity="0.5"
        d="M101.967 263.617L122.62 243.059L143.222 263.617L153 253.811L132.397 233.253L153 212.695L143.222 202.939L122.62 223.497L101.967 202.939L92.1891 212.695L112.792 233.253L92.1891 253.811L101.967 263.617Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        opacity="0.5"
        d="M101.967 365.061L122.62 344.503L143.222 365.061L153 355.305L132.397 334.747L153 314.19L143.222 304.384L122.62 324.941L101.967 304.384L92.1891 314.19L112.792 334.747L92.1891 355.305L101.967 365.061Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        opacity="0.5"
        d="M101.967 466.556L122.62 445.948L143.222 466.556L153 456.75L132.397 436.192L153 415.634L143.222 405.878L122.62 426.436L101.967 405.878L92.1891 415.634L112.792 436.192L92.1891 456.75L101.967 466.556Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        opacity="0.5"
        d="M9.77767 162.122L30.4304 141.564L51.0333 162.122L60.8109 152.366L40.208 131.808L60.8109 111.251L51.0333 101.445L30.4304 122.052L9.82755 101.445L3.00889e-05 111.251L20.6029 131.808L2.82917e-05 152.366L9.77767 162.122Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        opacity="0.5"
        d="M9.77767 263.617L30.4304 243.059L51.0333 263.617L60.8109 253.811L40.208 233.253L60.8109 212.695L51.0333 202.939L30.4304 223.497L9.82755 202.939L3.00911e-05 212.695L20.6029 233.253L2.82939e-05 253.811L9.77767 263.617Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        opacity="0.5"
        d="M9.77767 365.061L30.4304 344.503L51.0333 365.061L60.8109 355.305L40.208 334.747L60.8109 314.19L51.0333 304.384L30.4304 324.941L9.82755 304.384L3.00889e-05 314.19L20.6029 334.747L2.82917e-05 355.305L9.77767 365.061Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        opacity="0.5"
        d="M9.77767 466.556L30.4304 445.948L51.0333 466.556L60.8109 456.75L40.208 436.192L60.8109 415.634L51.0333 405.878L30.4304 426.436L9.82755 405.878L3.00911e-05 415.634L20.6029 436.192L2.82939e-05 456.75L9.77767 466.556Z"
        fill="white"
        fill-opacity="0.1"
      />
      <path
        d="M0.566337 517.128L9.82755 507.887L30.1479 528.163L30.4304 528.445L30.7129 528.163L51.0333 507.887L60.2449 517.079L39.9252 537.403L39.6421 537.686L39.9255 537.969L60.2446 558.244L51.0333 567.435L30.7129 547.159L30.4308 546.877L30.1482 547.159L9.77801 567.435L0.566335 558.244L20.8854 537.969L21.1692 537.686L20.8854 537.403L0.566337 517.128Z"
        stroke="#66FA93"
        stroke-width="0.8"
      />
    </svg>
  );
};

export default ExesVertical;
