import React from "react";
import "./popularPosts.styles.css";
import ClockIcon from "../../../svg/clockIcon";
import { BounceLoader } from "react-spinners";
import SadEmoj from "../../../svg/sadEmoj";

const PopularPosts = ({
  posts,
  getPostDate,
  extractHeading,
  loading,
  error,
}) => {
  return (
    <div class="popular-posts-container">
      <div className="popular-blogs-highlighter"></div>
      <h2 className="blog-card-title">Popular Post</h2>
      {error ? (
        <div className="posts-error">
          <SadEmoj />
          {error}
        </div>
      ) : loading ? (
        <div className="loading-state">
          <BounceLoader
            color="#67fb94"
            loading={loading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        posts.slice(0, 4).map((post) => (
          <div class="post">
            <img
              src={
                post.content?.contentEntities?.length > 0
                  ? post.content?.contentEntities[0]?.entityLocation
                  : "https://res.cloudinary.com/dhbe6wtod/image/upload/v1721319960/werkix/blog/blog01_zoooab.jpg"
              }
              class="thumbnail"
              alt="post thumbnail"
            />
            <div class="content">
              <a
                href="https://www.linkedin.com/company/88634432/admin/page-posts/published/"
                target="_blank"
                rel="nooper noreferrer"
              >
                {extractHeading(post.text?.text, 80)}
              </a>

              <p class="date">
                <ClockIcon />
                {getPostDate(post.created.time)}
              </p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default PopularPosts;
