import React, { useEffect, useRef, useState } from "react";
import "./video.styles.css";
import PlayBtn from "../../svg/abouPage/playBtn";
import PauseBtn from "../../svg/abouPage/pauseBtn";

const NormalVideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    let timer;
    if (isPlaying && !hovered) {
      timer = setTimeout(() => setShowControls(false), 3000);
    }
    return () => clearTimeout(timer);
  }, [isPlaying, hovered]);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      videoRef.current.requestFullscreen().catch((err) => {
        console.log(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  };
  const handleMouseEnter = () => {
    setHovered(true);
    setShowControls(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className="video-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <video
        ref={videoRef}
        className="video-element"
        poster="https://res.cloudinary.com/dhbe6wtod/image/upload/v1730293778/werkix/about-main/werkix-cover_w0zn5f.png"
      >
        <source
          src="https://res.cloudinary.com/dhbe6wtod/video/upload/v1727283595/werkix/about-main/werkix_website_2_jgasrw.mp4"
          title="founders video"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      {showControls && (
        <div className={`video-controls`} onClick={togglePlayPause}>
          {isPlaying ? <PauseBtn /> : <PlayBtn />}
        </div>
      )}

      <button className="full-screen-btn" onClick={toggleFullScreen}>
        Full Screen
      </button>
    </div>
  );
};

export default NormalVideoPlayer;
