import React from "react";

const XVectorGreen = ({ fill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="62"
      viewBox="0 0 61 62"
      fill="none"
    >
      <path
        d="M9.96748 1.01012L30.337 21.335L30.6198 21.6172L30.9024 21.3346L51.222 1.01046L60.4344 10.2496L40.1146 30.5249L39.8308 30.808L40.1146 31.0912L60.4337 51.3657L51.2224 60.5569L30.902 40.2811L30.6198 39.9995L30.3373 40.2807L9.9671 60.5572L0.755434 51.3657L21.0745 31.0912L21.3583 30.808L21.0745 30.5249L0.754715 10.2496L9.96748 1.01012Z"
        fill="white"
        fill-opacity="0.2"
        stroke="#66FA93"
        stroke-width="0.8"
      />
    </svg>
  );
};

export default XVectorGreen;
