import React, { useRef } from "react";
import BurgerMenu from "../../svg/burgerMenu";
import MenuOpenX from "../../svg/menuOpen";

const TopHeaderMobile = ({ openMenu, setOpenMenu }) => {
  return (
    <div className="mobile-header">
      {openMenu ? (
        <div className="mobile-menu">
          <MenuOpenX />
        </div>
      ) : (
        <div className="mobile-menu" onClick={() => setOpenMenu(true)}>
          <BurgerMenu />
        </div>
      )}
    </div>
  );
};

export default TopHeaderMobile;
