import XVectorComponent from "../XVector/xVector.component";
import "./whyUsHome.styles.css";
import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, delay } from "framer-motion";

const WhyUsHome = ({ main }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div id="why-werkix" className="why-us-home">
      <XVectorComponent />
      <div className={`${main ? "why-us-wrap" : "why-wrap-secondary"}`}>
        <div
          className={`${
            main ? "why-us-wrapper-left" : "why-us-wrapper-left-grid"
          }`}
        >
          {main ? (
            <div className="why-us-home-bg">
              <motion.img
                initial={{
                  scale: 0.5,
                }}
                animate={{
                  scale: 1,
                }}
                transition={{
                  duration: 5,
                  ease: "easeIn",
                }}
                ref={ref}
                src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1721137085/werkix/whatsnew/why-us_vcq0ou.jpg"
                alt=""
              />
            </div>
          ) : (
            <div className="why-us-grid">
              <img
                src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1718824838/werkix/home/peter_uqgjsw.png"
                alt=""
              />

              <motion.img
                initial={{
                  scale: 0,
                }}
                animate={{
                  scale: 1,
                }}
                transition={{
                  duration: 5,
                  ease: "easeIn",
                }}
                ref={ref}
                src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1718628311/werkix/home/DSC02902_b672cs.jpg"
                alt=""
              />
              <img
                src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1721232802/werkix/home/header-img-secondary_m6a05y.jpg"
                className="xms-img"
                alt=""
              />
            </div>
          )}
        </div>

        <div className="why-us-wrapper-right">
          <motion.div
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 1 }}
            ref={ref}
            className="home-title"
          >
            About Werkix
          </motion.div>
          <motion.div
            variants={{
              hidden: { opacity: 0, x: 100 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 1 }}
            ref={ref}
            className="home-heading"
          >
            Why Us?
          </motion.div>
          <p className={`${main ? "why-text" : "why-text-secondary"}`}>
            <span className="why-werkix-text1">
              Werkix is not merely a talent supplier; we are dedicated to
              crafting enduring partnerships. By collaborating with business
              leaders, HR professionals, and tech industry decision-makers, we
              add value and drive growth stories both personally and
              professionally. Werkix is transforming the global talent landscape
              by bridging the gap between tech innovators and emerging African
              talent.
            </span>
            <span className="why-werkix-text2">
              Our strategic presence in Malawi provides an effective incubation
              process, allowing us to export and connect Africa’s brightest
              minds with leading opportunities worldwide. We empower businesses
              by delivering tailored talent solutions that seamlessly integrate
              into your existing teams, ensuring that hires work exclusively for
              you, with your tools and processes.
            </span>
          </p>
        </div>
      </div>
      <div className="why-us-counter">
        <div className="why-us-details">
          <div className="counter-detail">
            <span className="home-stroke">100</span>
            <span className="home-stroke-subtitle">products</span>
          </div>
          <div className="counter-detail">
            <span className="home-stroke">15</span>
            <span className="home-stroke-subtitle">members</span>
          </div>
          <div className="counter-detail">
            <span className="home-stroke">50</span>
            <span className="home-stroke-subtitle">Projects</span>
          </div>
          <div className="counter-detail">
            <span className="home-stroke">50</span>
            <span className="home-stroke-subtitle">Clients</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUsHome;
