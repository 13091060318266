import ClockIcon from "../svg/contactPage/clockIcon";
import EnvelopIcon from "../svg/contactPage/envelopIcon";
import LocationIcon from "../svg/contactPage/locationIcon";
import PhoneIcon from "../svg/contactPage/phoneIcon";
import CyberIcon from "../svg/ourserviceHome/cyberIcon";
import DevelopmentIcon from "../svg/ourserviceHome/developmentIcon";
import ITSupportIcon from "../svg/ourserviceHome/iTSupportIcon";
import NetworkingIcon from "../svg/ourserviceHome/networkingIcon";
import WebDevIcon from "../svg/ourserviceHome/webDevIcon";

const services = [
  {
    service: "Software Development",
    icon: <DevelopmentIcon />,
    serviceDesc:
      "Werkix employs a rigorous screening process to shortlist the best software developers, cyber security experts, network engineers, and web developers. ",
  },
  {
    service: "IT Support  ",
    icon: <ITSupportIcon />,
    serviceDesc:
      "Our IT support engineers are meticulously selected for their advanced skills and communication prowess, ensuring seamless integration into your existing teams.",
  },
  {
    service: "Web Development",
    icon: <WebDevIcon />,
    serviceDesc:
      "Werkix employs a rigorous screening process to shortlist the best software developers, cyber security experts, network engineers, and web developers. ",
  },
  {
    service: "Cybersecurity ",
    icon: <CyberIcon />,
    serviceDesc:
      "We ensure to keep close relations with universities and finance sector. For over two decades we maintained relationships in the two sectors. Our specialists are trained in state-of-the-art labs and undergo constant upskilling",
  },
];

export default services;
