import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ClientLogosCarousel.styles.css";

const ClientLogosCarousel = ({ logos }) => {
  const settings = {
    infinite: true,
    speed: 4500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <a
            href={logo.website}
            target="_blank"
            rel="nooper noreferrer"
            key={index}
            className="carousel-slide"
          >
            <img
              id={`logo${index + 1}`}
              src={logo.logo}
              alt={`Client logo ${index + 1}`}
              className="client-logo"
            />
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default ClientLogosCarousel;
