import React from "react";

const Arrow = ({ fill }) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8418 6.625C15.8418 7.22266 15.377 7.6875 14.8125 7.6875H4.61914L8.10547 11.207C8.53711 11.6055 8.53711 12.3027 8.10547 12.7012C7.90625 12.9004 7.64062 13 7.375 13C7.07617 13 6.81055 12.9004 6.61133 12.7012L1.29883 7.38867C0.867188 6.99023 0.867188 6.29297 1.29883 5.89453L6.61133 0.582031C7.00977 0.150391 7.70703 0.150391 8.10547 0.582031C8.53711 0.980469 8.53711 1.67773 8.10547 2.07617L4.61914 5.5625H14.8125C15.377 5.5625 15.8418 6.06055 15.8418 6.625Z"
        fill={fill}
      />
    </svg>
  );
};

export default Arrow;
