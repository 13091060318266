import React from "react";

const MetaIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill={fill}
    >
      <path
        d="M2.59228 10.5127C2.59228 11.4292 2.79347 12.1327 3.05634 12.5584C3.40106 13.1159 3.91509 13.3521 4.43925 13.3521C5.11528 13.3521 5.73375 13.1842 6.9255 11.5359C7.88034 10.2148 9.00544 8.36025 9.76237 7.19766L11.0444 5.22797C11.9349 3.86006 12.9656 2.33944 14.1475 1.30866C15.1121 0.467344 16.1529 0 17.2003 0C18.9589 0 20.6339 1.01906 21.9159 2.93034C23.3189 5.0235 24 7.66003 24 10.3808C24 11.9983 23.6812 13.1868 23.1387 14.1258C22.6146 15.0338 21.593 15.9411 19.8745 15.9411V13.3521C21.3459 13.3521 21.7132 12 21.7132 10.4527C21.7132 8.24766 21.199 5.8005 20.0665 4.05206C19.2627 2.81175 18.2212 2.05397 17.0753 2.05397C15.8359 2.05397 14.8385 2.98866 13.7176 4.65553C13.1217 5.541 12.5099 6.62016 11.8231 7.83778L11.0669 9.17737C9.54787 11.8706 9.16313 12.484 8.40366 13.4964C7.07241 15.2692 5.93578 15.9411 4.43925 15.9411C2.66409 15.9411 1.54144 15.1723 0.846281 14.0139C0.278719 13.07 0 11.8314 0 10.4201L2.59228 10.5127Z"
        fill={fill}
      />
      <path
        d="M2.04395 3.11306C3.23251 1.28119 4.94757 0 6.91473 0C8.05407 0 9.18657 0.337219 10.3692 1.30284C11.6628 2.35856 13.0416 4.09706 14.7617 6.96234L15.3786 7.9905C16.8674 10.471 17.7145 11.7471 18.2103 12.3489C18.848 13.1217 19.2945 13.3521 19.8745 13.3521C21.3459 13.3521 21.7132 12 21.7132 10.4527L24 10.3808C24 11.9983 23.6813 13.1868 23.1387 14.1258C22.6146 15.0338 21.593 15.9411 19.8745 15.9411C18.8063 15.9411 17.8599 15.709 16.8132 14.7217C16.0087 13.9639 15.0681 12.6176 14.3444 11.4074L12.1919 7.81191C11.112 6.0075 10.1213 4.66219 9.54798 4.05281C8.93129 3.39769 8.13835 2.60644 6.8731 2.60644C5.84907 2.60644 4.97935 3.32512 4.25157 4.42425L2.04395 3.11306Z"
        fill={fill}
      />
      <path
        d="M6.873 2.60644C5.84897 2.60644 4.97925 3.32513 4.25147 4.42425C3.22247 5.9775 2.59228 8.29097 2.59228 10.5128C2.59228 11.4292 2.79347 12.1327 3.05634 12.5584L0.846281 14.0139C0.278719 13.07 0 11.8314 0 10.4201C0 7.85363 0.704438 5.17875 2.04394 3.11306C3.2325 1.28119 4.94756 0 6.91472 0L6.873 2.60644Z"
        fill={fill}
      />
    </svg>
  );
};

export default MetaIcon;
