import React from "react";

const SBackgroundLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="233px"
      height="892px"
      // style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          // style="opacity:0.954"
          fill="#79c049"
          d="M 4.5,210.5 C 64.1712,241.002 123.838,271.502 183.5,302C 185.359,303.558 186.859,305.391 188,307.5C 190.345,311.188 191.845,315.188 192.5,319.5C 129.83,288.076 67.1629,256.243 4.5,224C 3.85644,219.586 3.85644,215.086 4.5,210.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.943"
          fill="#7ac14a"
          d="M 2.5,274.5 C 71.292,309.796 140.292,345.129 209.5,380.5C 210.492,384.783 210.826,389.116 210.5,393.5C 204.448,391.313 198.448,388.813 192.5,386C 129.01,353.092 65.6763,320.592 2.5,288.5C 1.82011,283.88 1.82011,279.213 2.5,274.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.949"
          fill="#7ac14b"
          d="M -0.5,352.5 C -0.5,350.167 -0.5,347.833 -0.5,345.5C 0.164619,343.631 0.831285,341.631 1.5,339.5C 70.2157,374.656 139.216,409.656 208.5,444.5C 208.827,449.214 208.493,453.88 207.5,458.5C 138.366,422.995 69.0328,387.662 -0.5,352.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.957"
          fill="#79c04a"
          d="M -0.5,403.5 C 68.6388,438.152 137.472,473.152 206,508.5C 206.499,512.821 206.666,517.154 206.5,521.5C 137.137,487.057 68.1371,452.057 -0.5,416.5C -0.5,412.167 -0.5,407.833 -0.5,403.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.945"
          fill="#79c04a"
          d="M -0.5,467.5 C 67.8596,502.15 135.86,537.15 203.5,572.5C 204.492,576.783 204.826,581.116 204.5,585.5C 201.575,585.54 198.909,584.707 196.5,583C 130.877,549.159 65.21,515.659 -0.5,482.5C -0.5,477.5 -0.5,472.5 -0.5,467.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.953"
          fill="#7ac14b"
          d="M -0.5,533.5 C 66.87,567.851 134.203,602.185 201.5,636.5C 202.423,640.806 202.423,645.139 201.5,649.5C 190.958,645.231 180.625,640.397 170.5,635C 113.722,605.427 56.7218,576.261 -0.5,547.5C -0.5,542.833 -0.5,538.167 -0.5,533.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.961"
          fill="#79c04a"
          d="M -0.5,598.5 C 62.1848,629.509 124.518,661.009 186.5,693C 187.172,696.692 186.339,700.192 184,703.5C 182.612,704.554 181.112,704.72 179.5,704C 120.067,673.291 60.0666,642.791 -0.5,612.5C -0.5,607.833 -0.5,603.167 -0.5,598.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.954"
          fill="#79c04a"
          d="M -0.5,662.5 C 52.1052,689.306 104.772,716.472 157.5,744C 156.002,747.327 154.002,750.327 151.5,753C 149.447,753.325 147.447,752.991 145.5,752C 96.9049,726.87 48.2382,702.036 -0.5,677.5C -0.5,672.5 -0.5,667.5 -0.5,662.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.949"
          fill="#7ac14a"
          d="M -0.5,728.5 C 37.5502,747.191 75.2169,766.357 112.5,786C 107.871,789.986 102.538,791.986 96.5,792C 64.3273,775.425 31.9939,758.925 -0.5,742.5C -0.5,737.833 -0.5,733.167 -0.5,728.5 Z"
        />
      </g>
      <g>
        <path
          // style="opacity:0.95"
          fill="#7ac14c"
          d="M -0.5,793.5 C 16.7323,801.232 33.3989,809.899 49.5,819.5C 45.4511,822.05 41.2844,824.383 37,826.5C 24.5492,820.072 12.0492,813.738 -0.5,807.5C -0.5,802.833 -0.5,798.167 -0.5,793.5 Z"
        />
      </g>
    </svg>
  );
};

export default SBackgroundLeft;
