import WhyUsHome from "../../components/WhyUsHome/whyUsHome.component";
import AboutHome from "../../components/aboutHome/aboutHome.component";
import Banner from "../../components/banner/banner.component";
import BlogHome from "../../components/blogsHome/blogHome.component";
import CaseStudies from "../../components/caseStudies/caseStudies.component";
import ClientTestimonials from "../../components/clientTestimonial/clientTestimonial.component";
import ClientLogosCarousel from "../../components/clientsSlide/ClientLogosCarousel.component";
import OurServiceHome from "../../components/ourServiceHome/ourServiceHome.component";
import OurTeamHome from "../../components/ourTeamHome/ourTeamHome.component";
import SliderTest from "../../components/sliderTest/sliderTest.component";
import SlidingText from "../../components/slidingText/slidingText.component";
import TalentTestimonials from "../../components/talentTestimonial/talentTestimonial.component";
import logos from "../../data/logos";
import "./home.styles.css";
import React from "react";

const Home = ({
  posts,
  error,
  loading,
  getPostDate,
  extractHeading,
  checkTextLength,
}) => {
  return (
    <div className="home">
      <Banner />
      <AboutHome />
      <OurServiceHome />
      <ClientLogosCarousel logos={logos} />
      <CaseStudies />
      <WhyUsHome main />
      <OurTeamHome />
      <SlidingText />
      <TalentTestimonials />
      <ClientTestimonials />
      <BlogHome
        posts={posts}
        error={error}
        loading={loading}
        getPostDate={getPostDate}
        extractHeading={extractHeading}
        checkTextLength={checkTextLength}
      />
    </div>
  );
};

export default Home;
