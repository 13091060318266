import React from "react";

const ClockIcon = () => {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3487 5.88206C16.8428 5.86806 5.88873 16.8598 5.88873 30.4032C5.88873 43.9914 16.8951 54.9606 30.6123 54.8626C44.4065 54.7645 54.9184 43.3733 54.8624 30.3462C54.8132 16.8447 43.9009 5.8892 30.3487 5.88206ZM30.3487 5.88206L30.3481 6.38206L30.3484 5.88206C30.3485 5.88206 30.3486 5.88206 30.3487 5.88206ZM30.3822 0.875065L30.3835 0.875063C46.7731 0.841353 60.1865 14.367 59.8695 31.0039C59.5728 46.534 46.8336 60.1259 29.8687 59.8765C13.6262 59.6338 0.537693 46.2367 0.881629 29.721L0.881629 29.721C1.21202 13.8284 14.2264 0.868321 30.3822 0.875065ZM28.387 12.9028H32.4031C32.4031 12.9028 32.4032 12.9029 32.4033 12.9029C32.4042 12.9039 32.4049 12.9049 32.4052 12.9058C32.4055 12.9064 32.4059 12.9075 32.4059 12.9102V32.3575L32.4053 32.3581H18.1627L18.1621 32.3575V28.2774L18.1627 28.2768H27.3858C27.9362 28.2768 28.3864 27.8267 28.3864 27.2763V12.9034L28.387 12.9028Z"
        stroke="#67FB94"
      />
    </svg>
  );
};

export default ClockIcon;
