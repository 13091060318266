import React, { useEffect, useRef, useState } from "react";
import "./caseStudies.styles.css";
import ExesVertical from "../../svg/caseStudies/ExesVertical";
import { useInView, useAnimation } from "framer-motion";
import caseStudies from "../../data/caseStudies";
import CaseStudyColumn from "../cards/caseStudyCard/caseStudyColumn.component";
import CaseStudyRow from "../cards/caseStudyCard/caseStudyRow.component";
import { shuffleArray } from "../../helpers/shuffleArray";
import ArrowButton from "../buttons/arrowButton.component";

const CaseStudies = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  const [randomStudies, setRandomStudies] = useState([]);

  const getRandomCaseStudies = (caseStudies) => {
    const shuffledStudies = shuffleArray(caseStudies);
    return shuffledStudies.slice(0, 2);
  };

  useEffect(() => {
    setRandomStudies(getRandomCaseStudies(caseStudies));

    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls, setRandomStudies]);
  return (
    <div id="case-studies" className="case-studies-home">
      <div className="case-study-bg"></div>
      <span className="case-study-exes">
        {" "}
        <ExesVertical />
      </span>
      <div className="case-studies-wrap container">
        <div className="case-studies-header">
          <div className="left-header">
            <div className="home-title">our Case Study</div>
            <div className="home-heading">Check Our Latest Case Studies</div>
          </div>
          <div className="right-header">
            <div>
              <ArrowButton onClick={() => getRandomCaseStudies(caseStudies)} />
            </div>

            <ArrowButton
              right
              onClick={() => getRandomCaseStudies(caseStudies)}
            />
          </div>
        </div>

        <div className="case-studies-content">
          <div className="slider-container row-grid">
            {caseStudies.slice(0, 2).map((casestudy) => (
              <CaseStudyColumn casestudy={casestudy} key={casestudy.id} />
            ))}
          </div>

          {caseStudies.slice(2, 4).map((casestudy) => (
            <CaseStudyRow casestudy={casestudy} key={casestudy.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
