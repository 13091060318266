import React from "react";

const PauseBtn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="67"
      height="67"
      fill="none"
      viewBox="0 0 1024 1024"
      id="pause"
    >
      <path
        fill="#fff"
        fill-rule="evenodd"
        d="M462 337H362V687H462V337zM337 312V712H487V312H337zM662 337H562V687H662V337zM537 312V712H687V312H537z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};

export default PauseBtn;
