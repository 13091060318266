import { Link } from "react-router-dom";
import InstagramIcon from "../../svg/socialIcons/instagram";
import "./footer.styles.css";
import React from "react";
import LinkedInIcon from "../../svg/socialIcons/linkedIn";
import MetaIcon from "../../svg/socialIcons/meta";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="main-footer">
        <div className="footer-overlay">
          <div className="footer-wrapper">
            <div className="top-footer">
              <div className="footer-column">
                <div className="footer-logo">
                  {" "}
                  <Link to={"/"}>
                    <img
                      src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1728482531/werkix/logos/high%20resolution%20logos/werkix_new_logo01_oqznwr.png"
                      alt=""
                    />
                  </Link>
                </div>

                <p className="footer-about">
                  Werkix was founded to address the lack of real opportunities
                  for tech talent in Malawi. Our mission is to bridge the gap
                  between local African tech talent and the rest of the world.
                </p>
                <div className="social-links">
                  <a
                    href="https://www.linkedin.com/company/werkix/"
                    target="_blank"
                    rel="nooper noreferrer"
                  >
                    {" "}
                    <LinkedInIcon />
                  </a>

                  <a
                    href="https://www.facebook.com/profile.php?id=100086435430979"
                    target="_blank"
                    rel="nooper noreferrer"
                  >
                    <MetaIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/werkix.io/"
                    target="_blank"
                    rel="nooper noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </div>
              </div>
              <div className="footer-column">
                <div className="footer-heading">Event List</div>
                <div className="footer-column-items">
                  <Link to={"/blog"}>News</Link>
                  <Link to={"/clients"}>Case studies</Link>
                  <Link to={"/"}>Mission</Link>
                </div>
              </div>
              <div className="footer-column">
                <div className="footer-heading">Quick Links</div>
                <div className="footer-column-items">
                  <Link to={"/"}>Home</Link>
                  <Link to={"/about"}>About us</Link>
                  <Link to={"/contact"}>Work with us</Link>
                </div>
              </div>
              <div className="footer-column">
                <div className="footer-heading">Offices</div>
                <div className="footer-column-items">
                  {" "}
                  <span className="location">Blantyre, Malawi, Africa</span>
                  <span>New York, United States</span>
                  <span>London, UK</span>
                </div>
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="footer-bottom">
            &copy; Werkix LLC {currentYear} | All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
