import React from "react";

const OnboardingIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill={fill}
    >
      <g clip-path="url(#clip0_275_1868)">
        <path
          d="M35 25.1562C33.0531 25.1562 31.1499 25.7336 29.5311 26.8152C27.9123 27.8969 26.6506 29.4342 25.9056 31.233C25.1605 33.0317 24.9656 35.0109 25.3454 36.9204C25.7252 38.8299 26.6628 40.5839 28.0394 41.9606C29.4161 43.3373 31.1701 44.2748 33.0796 44.6546C34.9891 45.0344 36.9683 44.8395 38.767 44.0944C40.5658 43.3494 42.1031 42.0877 43.1848 40.4689C44.2664 38.8501 44.8438 36.9469 44.8438 35C44.8406 32.3903 43.8024 29.8883 41.9571 28.0429C40.1117 26.1976 37.6098 25.1594 35 25.1562ZM35 42.6562C33.4857 42.6562 32.0055 42.2072 30.7464 41.3659C29.4874 40.5247 28.506 39.3289 27.9266 37.9299C27.3471 36.5309 27.1954 34.9915 27.4909 33.5063C27.7863 32.0212 28.5155 30.657 29.5862 29.5862C30.657 28.5155 32.0212 27.7863 33.5063 27.4909C34.9915 27.1954 36.5309 27.3471 37.9299 27.9265C39.3289 28.506 40.5247 29.4874 41.3659 30.7464C42.2072 32.0055 42.6563 33.4857 42.6563 35C42.6539 37.0299 41.8466 38.9759 40.4112 40.4112C38.9759 41.8466 37.0299 42.6539 35 42.6562Z"
          fill={fill}
        />
        <path
          d="M49.8947 20.6973C50.0272 20.7522 50.1691 20.7808 50.3125 20.7815H66.7188C67.0088 20.7815 67.287 20.6662 67.4921 20.4611C67.6973 20.256 67.8125 19.9778 67.8125 19.6877C67.8125 19.3976 67.6973 19.1194 67.4921 18.9143C67.287 18.7092 67.0088 18.594 66.7188 18.594H52.9528L69.6795 1.86726C69.8788 1.66098 69.989 1.3847 69.9865 1.09792C69.984 0.81114 69.869 0.536814 69.6662 0.334024C69.4634 0.131234 69.1891 0.0162052 68.9023 0.0137132C68.6155 0.0112212 68.3393 0.121465 68.133 0.3207L51.4062 17.0474V3.28148C51.4062 2.9914 51.291 2.7132 51.0859 2.50808C50.8808 2.30297 50.6026 2.18773 50.3125 2.18773C50.0224 2.18773 49.7442 2.30297 49.5391 2.50808C49.334 2.7132 49.2188 2.9914 49.2188 3.28148V19.6877C49.2194 19.8311 49.248 19.9731 49.303 20.1055C49.414 20.3734 49.6268 20.5863 49.8947 20.6973Z"
          fill={fill}
        />
        <path
          d="M19.6877 2.18773C19.3976 2.18773 19.1194 2.30297 18.9143 2.50808C18.7092 2.7132 18.594 2.9914 18.594 3.28148V17.0474L1.86726 0.3207C1.66098 0.121465 1.3847 0.0112212 1.09792 0.0137132C0.81114 0.0162052 0.536814 0.131234 0.334024 0.334024C0.131234 0.536814 0.0162052 0.81114 0.0137132 1.09792C0.0112212 1.3847 0.121465 1.66098 0.3207 1.86726L17.0474 18.594H3.28148C2.9914 18.594 2.7132 18.7092 2.50808 18.9143C2.30297 19.1194 2.18773 19.3976 2.18773 19.6877C2.18773 19.9778 2.30297 20.256 2.50808 20.4611C2.7132 20.6662 2.9914 20.7815 3.28148 20.7815H19.6877C19.9778 20.7815 20.256 20.6662 20.4611 20.4611C20.6662 20.256 20.7815 19.9778 20.7815 19.6877V3.28148C20.7815 2.9914 20.6662 2.7132 20.4611 2.50808C20.256 2.30297 19.9778 2.18773 19.6877 2.18773Z"
          fill={fill}
        />
        <path
          d="M52.9528 51.4062H66.7188C67.0088 51.4062 67.287 51.291 67.4921 51.0859C67.6973 50.8808 67.8125 50.6026 67.8125 50.3125C67.8125 50.0224 67.6973 49.7442 67.4921 49.5391C67.287 49.334 67.0088 49.2188 66.7188 49.2188H50.3125C50.0224 49.2188 49.7442 49.334 49.5391 49.5391C49.334 49.7442 49.2188 50.0224 49.2188 50.3125V66.7188C49.2188 67.0088 49.334 67.287 49.5391 67.4921C49.7442 67.6973 50.0224 67.8125 50.3125 67.8125C50.6026 67.8125 50.8808 67.6973 51.0859 67.4921C51.291 67.287 51.4062 67.0088 51.4062 66.7188V52.9528L68.133 69.6795C68.3393 69.8788 68.6155 69.989 68.9023 69.9865C69.1891 69.984 69.4634 69.869 69.6662 69.6662C69.869 69.4634 69.984 69.1891 69.9865 68.9023C69.989 68.6155 69.8788 68.3393 69.6795 68.133L52.9528 51.4062Z"
          fill={fill}
        />
        <path
          d="M20.1051 49.303C19.9728 49.2475 19.8307 49.2189 19.6873 49.2188H3.28102C2.99094 49.2188 2.71274 49.334 2.50762 49.5391C2.3025 49.7442 2.18727 50.0224 2.18727 50.3125C2.18727 50.6026 2.3025 50.8808 2.50762 51.0859C2.71274 51.291 2.99094 51.4062 3.28102 51.4062H17.047L0.320237 68.133C0.215773 68.2339 0.132449 68.3546 0.0751262 68.488C0.0178039 68.6214 -0.0123686 68.765 -0.0136306 68.9102C-0.0148926 69.0554 0.0127812 69.1994 0.0677759 69.3339C0.122771 69.4683 0.203985 69.5904 0.30668 69.6931C0.409375 69.7958 0.531494 69.877 0.665912 69.932C0.80033 69.987 0.944355 70.0147 1.08958 70.0134C1.23481 70.0121 1.37833 69.982 1.51177 69.9246C1.64522 69.8673 1.7659 69.784 1.8668 69.6795L18.5935 52.9528V66.7188C18.5935 67.0088 18.7088 67.287 18.9139 67.4921C19.119 67.6973 19.3972 67.8125 19.6873 67.8125C19.9774 67.8125 20.2556 67.6973 20.4607 67.4921C20.6658 67.287 20.781 67.0088 20.781 66.7188V50.3125C20.7803 50.1691 20.7517 50.0272 20.6968 49.8947C20.5858 49.6268 20.373 49.414 20.1051 49.303Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_275_1868">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OnboardingIcon;
