import React from "react";
import DividerQuote from "../../dividerQuote/dividerQuote.component";
import "./ourTalentCard.styles.css";
import BigDot from "../../../svg/connector/bigDot";
import TriangleConnectors from "../../../svg/connector/triangleConnector";
import LeftConnectors from "../../../svg/connector/leftConnectors";
import LeftTestimonialBg from "../../../vectors/leftTestimonialBg";
import RightTestimonialBg from "../../../vectors/rightTestimonialBg";

const OurTalentCard = ({ primary, name, profile, specialisation, content }) => {
  return (
    <div
      className={`testimonial ${
        primary ? "testimonial-primary" : "testimonial-secondary"
      }`}
    >
      {primary ? (
        <>
          <div className="testimonial-content">
            <div className="left-vectors">
              <LeftTestimonialBg />
            </div>
            <p>{content}</p>
            <DividerQuote />
            <div className="testimonial-author">
              <p>{name}</p>
              <p>{specialisation}</p>
            </div>
          </div>
          <div className="testimonial-divider"></div>
          <div
            className={` testimonial-image ${
              primary ? "translate-primary" : "translate-secondary"
            }`}
          >
            <img src={profile} alt="profile pic" />
          </div>
        </>
      ) : (
        <>
          <div
            className={` testimonial-image ${
              primary ? "translate-primary" : "translate-secondary"
            }`}
          >
            <img src={profile} alt="profile pic" />
          </div>
          <div className="testimonial-divider"></div>
          <div className="testimonial-content">
            <div className="right-vectors">
              <RightTestimonialBg />
            </div>
            <p>{content}</p>
            <DividerQuote />
            <div className="testimonial-author">
              <p>{name}</p>
              <p>{specialisation}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OurTalentCard;
