import React from "react";
import SliderButton from "../slider-button/sliderButton.component";
import "./sliderControls.styles.css";

const SliderControls = ({ fullName, position, imgSrc }) => {
  return (
    <div className="slider-controls-container">
      <div className="logo-container">
        <img src={imgSrc} alt="" />
      </div>
      <div className="personal-info">
        <div className="full-name">{fullName}</div>
        <div className="person-position">{position}</div>
      </div>
      <div className="slide-btn">
        <SliderButton direction="left" />
        <SliderButton direction="right" />
      </div>
    </div>
  );
};

export default SliderControls;
