import React from "react";

const PinterestPJIcon = ({ fill }) => {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.98438 0.386719C5.82031 0.386719 7.5 1.65625 7.5 3.58984C7.5 5.40625 6.5625 7.4375 4.49219 7.4375C3.98438 7.4375 3.37891 7.18359 3.125 6.73438C2.69531 8.49219 2.71484 8.76562 1.73828 10.1133C1.64062 10.1523 1.66016 10.1523 1.60156 10.0742C1.5625 9.70312 1.52344 9.35156 1.52344 8.98047C1.52344 7.78906 2.07031 6.05078 2.34375 4.89844C2.1875 4.58594 2.14844 4.23438 2.14844 3.90234C2.14844 2.33984 3.98438 2.10547 3.98438 3.39453C3.98438 4.15625 3.45703 4.87891 3.45703 5.62109C3.45703 6.10938 3.88672 6.46094 4.375 6.46094C5.72266 6.46094 6.13281 4.52734 6.13281 3.49219C6.13281 2.10547 5.15625 1.34375 3.80859 1.34375C2.26562 1.34375 1.07422 2.45703 1.07422 4.01953C1.07422 4.78125 1.54297 5.17188 1.54297 5.34766C1.54297 5.50391 1.42578 6.03125 1.23047 6.03125C0.761719 6.03125 0 5.25 0 3.88281C0 1.71484 1.97266 0.386719 3.98438 0.386719Z"
        fill={fill}
      />
    </svg>
  );
};

export default PinterestPJIcon;
