import services from "../../data/services";
import ServiceHomeCard from "../cards/serviceHomeCard/serviceHomeCard.component";
import "./ourServiceHome.styles.css";
import React from "react";

const OurServiceHome = () => {
  return (
    <div className="service-home" id="our-service">
      <div className="service-home-wrap">
        <div className="service-home-content">
          <div className="home-title">Our Service</div>
          <div className="home-heading">Our Hires Are Trained In:</div>
          <div className="our-service-statement">
            At Werkix, we have a unique approach to hiring in different
            categories that sets us apart from other tech recruiters. With over
            20 years of experience in the tech industry, we have built strong
            relationships that allow us to identify and nurture talent. We are
            intertwined and monitor groups and networks utilized by the tech
            community. We keep track of thriving talent working at companies
            creating magic and doing great things. We get to know the character
            of individuals through the connections we have built in the
            industry. It is our own Werkix referencing. We collaborate with
            universities through scholarship programs to spot promising
            individuals, and we offer paid internships to develop them into
            exceptional candidates. Our reward programs incentivize learning of
            skills required by our clients, and we focus on communication and
            best practices to ensure that our people are well-prepared to
            seamlessly integrate into international teams. Continuous upskilling
            is tied to bonuses, ensuring that our team is always pushing past
            barriers. <br />
            <br />
            <span className="success-story">
              We don't just recruit for tech roles - we build success stories.
            </span>
          </div>
          <div className="cards-wrapper">
            <div className="service-home-cards">
              {services.map((service, i) => (
                <ServiceHomeCard
                  key={i}
                  serviceIcon={service.icon}
                  serviceName={service.service}
                  serviceDescription={service.serviceDesc}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServiceHome;
