import React from "react";

const CyberIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
    >
      <g clip-path="url(#clip0_770_46)">
        <path
          d="M31 50.375C29.8504 50.375 28.7266 50.7159 27.7708 51.3546C26.8149 51.9933 26.0699 52.9011 25.63 53.9632C25.19 55.0252 25.0749 56.194 25.2992 57.3215C25.5235 58.449 26.0771 59.4847 26.8899 60.2976C27.7028 61.1105 28.7385 61.664 29.866 61.8883C30.9936 62.1126 32.1623 61.9975 33.2243 61.5576C34.2864 61.1176 35.1942 60.3726 35.8329 59.4168C36.4716 58.4609 36.8125 57.3371 36.8125 56.1875C36.8107 54.6465 36.1977 53.1691 35.1081 52.0794C34.0184 50.9898 32.541 50.3768 31 50.375ZM31 60.0625C30.2336 60.0625 29.4844 59.8352 28.8472 59.4094C28.2099 58.9837 27.7133 58.3785 27.42 57.6704C27.1267 56.9623 27.0499 56.1832 27.1995 55.4315C27.349 54.6798 27.718 53.9894 28.26 53.4475C28.8019 52.9055 29.4924 52.5365 30.244 52.387C30.9957 52.2374 31.7748 52.3142 32.4829 52.6075C33.191 52.9008 33.7962 53.3974 34.2219 54.0347C34.6477 54.6719 34.875 55.4211 34.875 56.1875C34.875 57.2152 34.4667 58.2008 33.74 58.9275C33.0133 59.6542 32.0277 60.0625 31 60.0625Z"
          fill="#67FB94"
        />
        <path
          d="M11.625 13.5625C11.625 12.4129 11.2841 11.2891 10.6454 10.3333C10.0067 9.37739 9.09895 8.63239 8.03685 8.19245C6.97475 7.75252 5.80606 7.63741 4.67854 7.86169C3.55103 8.08596 2.51534 8.63955 1.70244 9.45244C0.889552 10.2653 0.335965 11.301 0.111688 12.4285C-0.112588 13.5561 0.00251848 14.7248 0.442453 15.7869C0.882387 16.8489 1.62739 17.7567 2.58325 18.3954C3.53911 19.0341 4.6629 19.375 5.8125 19.375C7.35352 19.3732 8.83091 18.7602 9.92058 17.6706C11.0102 16.5809 11.6232 15.1035 11.625 13.5625ZM5.8125 17.4375C5.0461 17.4375 4.29691 17.2102 3.65967 16.7844C3.02243 16.3587 2.52576 15.7535 2.23247 15.0454C1.93918 14.3373 1.86244 13.5582 2.01196 12.8065C2.16148 12.0549 2.53054 11.3644 3.07246 10.8225C3.61439 10.2805 4.30485 9.91148 5.05653 9.76196C5.8082 9.61244 6.58734 9.68918 7.2954 9.98247C8.00346 10.2758 8.60866 10.7724 9.03445 11.4097C9.46024 12.0469 9.6875 12.7961 9.6875 13.5625C9.6875 14.5902 9.27925 15.5758 8.55254 16.3025C7.82584 17.0292 6.84022 17.4375 5.8125 17.4375Z"
          fill="#67FB94"
        />
        <path
          d="M51.9404 45.3C49.1079 48.8322 45.4445 51.6077 41.2774 53.3784C41.0702 53.4648 40.8995 53.6204 40.7943 53.8187C40.6891 54.017 40.6561 54.2456 40.7008 54.4656C40.7455 54.6856 40.8653 54.8832 41.0395 55.0246C41.2138 55.1661 41.4317 55.2427 41.6562 55.2413C41.7863 55.241 41.915 55.215 42.035 55.1648C46.497 53.2672 50.4197 50.2941 53.4527 46.5109C53.609 46.31 53.68 46.0556 53.6502 45.8028C53.6205 45.5499 53.4924 45.319 53.2936 45.1598C53.0949 45.0007 52.8415 44.9262 52.5883 44.9524C52.3351 44.9787 52.1023 45.1035 51.9404 45.3Z"
          fill="#67FB94"
        />
        <path
          d="M3.87502 28.4647C3.87496 27.0929 3.97891 25.723 4.18599 24.3669C4.21447 24.1178 4.14524 23.8673 3.99287 23.6681C3.84049 23.469 3.61683 23.3367 3.36892 23.2991C3.12101 23.2614 2.86816 23.3214 2.66354 23.4663C2.45892 23.6113 2.31848 23.8299 2.27174 24.0763C2.04941 25.5285 1.93769 26.9955 1.93752 28.4647C1.93385 31.7499 2.49452 35.0113 3.59505 38.1067C3.63576 38.2289 3.70046 38.3419 3.78535 38.4388C3.87025 38.5358 3.97363 38.6148 4.08946 38.6713C4.20528 38.7278 4.33122 38.7606 4.45988 38.7679C4.58855 38.7751 4.71737 38.7566 4.83879 38.7134C4.96021 38.6702 5.0718 38.6032 5.16701 38.5164C5.26223 38.4295 5.33916 38.3246 5.3933 38.2076C5.44744 38.0907 5.47771 37.9641 5.48232 37.8353C5.48694 37.7065 5.46581 37.5781 5.42017 37.4576C4.3941 34.5705 3.87145 31.5287 3.87502 28.4647Z"
          fill="#67FB94"
        />
        <path
          d="M20.7225 53.379C16.555 51.6081 12.8914 48.8319 10.0595 45.2986C9.98066 45.1973 9.88249 45.1127 9.77068 45.0497C9.65887 44.9866 9.53564 44.9465 9.40817 44.9314C9.28069 44.9164 9.1515 44.9269 9.02811 44.9623C8.90472 44.9976 8.78959 45.0571 8.68939 45.1374C8.5892 45.2176 8.50595 45.3169 8.44448 45.4296C8.38302 45.5423 8.34455 45.6661 8.33133 45.7937C8.3181 45.9214 8.33038 46.0504 8.36745 46.1733C8.40452 46.2962 8.46564 46.4105 8.54726 46.5096C11.5801 50.2928 15.5028 53.2659 19.9649 55.1634C20.0849 55.2136 20.2137 55.2396 20.3437 55.24C20.5674 55.2403 20.7842 55.1632 20.9575 55.0219C21.1308 54.8805 21.2498 54.6836 21.2944 54.4644C21.339 54.2453 21.3064 54.0175 21.2022 53.8196C21.0979 53.6218 20.9285 53.4661 20.7225 53.379Z"
          fill="#67FB94"
        />
        <path
          d="M56.1875 7.75C55.0379 7.75 53.9141 8.0909 52.9583 8.72958C52.0024 9.36827 51.2574 10.2761 50.8175 11.3382C50.3775 12.4002 50.2624 13.5689 50.4867 14.6965C50.711 15.824 51.2646 16.8597 52.0774 17.6726C52.8903 18.4855 53.926 19.039 55.0535 19.2633C56.1811 19.4876 57.3498 19.3725 58.4118 18.9326C59.4739 18.4926 60.3817 17.7476 61.0204 16.7918C61.6591 15.8359 62 14.7121 62 13.5625C61.9982 12.0215 61.3852 10.5441 60.2956 9.45442C59.2059 8.36476 57.7285 7.75179 56.1875 7.75ZM56.1875 17.4375C55.4211 17.4375 54.6719 17.2102 54.0347 16.7844C53.3974 16.3587 52.9008 15.7535 52.6075 15.0454C52.3142 14.3373 52.2374 13.5582 52.387 12.8065C52.5365 12.0548 52.9055 11.3644 53.4475 10.8225C53.9894 10.2805 54.6799 9.91147 55.4315 9.76196C56.1832 9.61244 56.9623 9.68918 57.6704 9.98247C58.3785 10.2758 58.9837 10.7724 59.4094 11.4097C59.8352 12.0469 60.0625 12.7961 60.0625 13.5625C60.0625 14.5902 59.6542 15.5758 58.9275 16.3025C58.2008 17.0292 57.2152 17.4375 56.1875 17.4375Z"
          fill="#67FB94"
        />
        <path
          d="M58.6239 23.2616C58.3701 23.3008 58.1422 23.4391 57.9904 23.6462C57.8385 23.8533 57.7751 24.1122 57.814 24.366C58.0213 25.7221 58.1253 27.092 58.125 28.4638C58.1272 31.5281 57.603 34.57 56.575 37.4567C56.4889 37.6989 56.5026 37.9653 56.6129 38.1974C56.7233 38.4295 56.9214 38.6082 57.1635 38.6943C57.4057 38.7804 57.6721 38.7667 57.9042 38.6563C58.1363 38.546 58.315 38.3479 58.4011 38.1058C59.993 33.6084 60.4476 28.7871 59.7244 24.0715C59.6852 23.8185 59.5475 23.5912 59.3413 23.4395C59.135 23.2877 58.8771 23.2238 58.6239 23.2616Z"
          fill="#67FB94"
        />
        <path
          d="M35.6927 1.92492C38.6676 2.39754 41.5413 3.36931 44.1925 4.7992C45.4009 5.44888 46.5583 6.18919 47.6548 7.01376C47.8603 7.16792 48.1187 7.23411 48.373 7.19778C48.6274 7.16144 48.8569 7.02556 49.011 6.82001C49.1652 6.61447 49.2314 6.35611 49.1951 6.10176C49.1587 5.84741 49.0228 5.61792 48.8173 5.46376C47.6415 4.58062 46.4009 3.78724 45.106 3.09032C42.2639 1.55865 39.1836 0.51779 35.9949 0.0116364C35.744 -0.0222203 35.4899 0.0434688 35.2868 0.194645C35.0838 0.345821 34.948 0.570473 34.9085 0.82052C34.869 1.07057 34.9289 1.32614 35.0755 1.53255C35.222 1.73896 35.4436 1.87979 35.6927 1.92492Z"
          fill="#67FB94"
        />
        <path
          d="M13.764 7.20886C13.9737 7.20942 14.1778 7.14138 14.3453 7.01511C15.4416 6.19034 16.5991 5.45003 17.8076 4.80054C20.4591 3.3705 23.3331 2.39873 26.3084 1.92626C26.5622 1.88593 26.7896 1.7464 26.9406 1.53838C27.0916 1.33036 27.1537 1.07089 27.1134 0.817044C27.0731 0.563199 26.9335 0.335775 26.7255 0.184802C26.5175 0.0338294 26.258 -0.0283246 26.0042 0.0120131C22.8144 0.518511 19.7331 1.56003 16.8902 3.09264C15.595 3.78856 14.3547 4.58199 13.1799 5.46608C13.0172 5.58807 12.897 5.75815 12.8364 5.95223C12.7758 6.1463 12.7778 6.35453 12.8421 6.54742C12.9064 6.74031 13.0297 6.90808 13.1947 7.02697C13.3596 7.14585 13.5578 7.20983 13.7611 7.20983L13.764 7.20886Z"
          fill="#67FB94"
        />
      </g>
      <defs>
        <clipPath id="clip0_770_46">
          <rect width="62" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CyberIcon;
