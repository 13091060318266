import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion, useInView, useAnimation, delay } from "framer-motion";

const CaseStudyRow = ({ casestudy }) => {
  const { id, name, image, subheading } = casestudy;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <div className="slider-container">
      <Link to={`/clients/${id}`} className="slider">
        <motion.img
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
          }}
          transition={{
            duration: 8,
            ease: "easeIn",
          }}
          ref={ref}
          src={image}
          alt=""
        />
        <div className="case-desc">
          {" "}
          <span className="case-study-subheading">{subheading}</span>
          <span className="case-study-heading">{name}</span>
        </div>
      </Link>
    </div>
  );
};

export default CaseStudyRow;
