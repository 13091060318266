import { Link } from "react-router-dom";
import ArrowLeft from "../../../svg/ourserviceHome/arrowLeft";
import ArrowRightNofill from "../../../svg/ourserviceHome/arrowRightNofill";
import "./teamMember.styles.css";

import React from "react";

const TeamMemberCard = ({ id, image, name, position, mainCard }) => {
  return (
    <Link to={`/team/${id}`} className="team-member-card">
      <div className="card-wrap">
        <div className="team-member-picture">
          <img src={image} alt="" />
        </div>
        <div className={`${mainCard ? "team-member-details" : ""}`}>
          <div className="member-info">
            <span className="name">{name}</span>
            <span
              className={`stroke-subheading ${
                mainCard ? "position" : "secondary-pos"
              }`}
            >
              {position}
            </span>
          </div>
          {mainCard && (
            <div className="member-arrow">
              <ArrowRightNofill fill={"#fff"} />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default TeamMemberCard;
