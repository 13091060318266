import React, { useEffect, useRef } from "react";
import "./about.style.css";
import Nav from "../../components/nav/nav.component";
import AboutGallery from "./galler";
import AboutCardHome from "../../components/cards/aboutCardHome/aboutCardHome.component";
import NormalVideoPlayer from "../../components/video/video.component";
import DividerQuote from "../../components/dividerQuote/dividerQuote.component";
import SliderControls from "../../components/sliderControls/sliderControls.component";
import SBackgroundLeft from "../../svg/abouPage/sBackgroundLeft";
import SBackgroundRight from "../../svg/abouPage/sBackgroundRight";
import { motion, useInView, useAnimation, delay } from "framer-motion";
import XVectorGreen from "../../svg/contactPage/xVectorGreen";
import XVectorComponent from "../../components/XVector/xVector.component";
import ExpertiseAndQuality from "../../svg/ourserviceHome/expertiseAndQuality";
import whyWerkix from "../../data/whyWerkix";
import ServiceMainCard from "../../components/cards/serviceMainCard/serviceMainCard.component";

const AboutPage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 2.5 }}
      ref={ref}
      className="about-page"
    >
      <Nav page="about" />

      <div className="about-header">
        <div className="about-header-bg">
          <div className="page-heading">About Us</div>
        </div>
      </div>

      <div className="about-content">
        <div className="about-page-gallery">
          <div className="gallery-vector">
            {" "}
            <XVectorGreen />
          </div>
          <div className="gallery-vector2">
            {" "}
            <XVectorComponent />
          </div>
          <div className="about-page-left">
            <AboutGallery />
          </div>
          <div className="about-page-right">
            <div className="about-right-content">
              <div className="home-title">About Company</div>
              <div className="home-heading">This is All about Talents</div>
              <div className="home-heading-mobile">All about Talents</div>
              <p>
                At Werkix, we are fueled by a passion to reshape the tech
                industry by seamlessly connecting talented individuals in Africa
                with abundant global market opportunities. With decades of
                experience in internet connectivity, digital transformation, and
                software development, we have pinpointed a critical inefficiency
                in the utilization of tech talent. Many skilled professionals,
                despite their extensive education and training, are often found
                in unrelated roles due to the sluggish industry growth in their
                regions
              </p>
              <p>
                The COVID-19 pandemic accelerated the global adoption of remote
                work; however, African tech talent was largely overlooked,
                grappling with a lack of exposure, resources, and opportunities
                to upskill. This is where Werkix steps in as an innovative
                partner and trusted advisor. We empower skilled individuals by
                linking them with US and European clients, nurturing not just
                employment, but career paths that are both challenging and
                fulfilling.
              </p>
            </div>
          </div>
        </div>
        {/* Services Section */}
        <section className="services-section">
          <h3 className="service-subheading">OUR SERVICES</h3>
          <h2 className="service-heading">Why Choose Werkix?</h2>
          <div className="service-divider divider-top"></div>

          {whyWerkix.map((service) => (
            <ServiceMainCard service={service} />
          ))}
        </section>

        {/* Cofounders section  */}
        <div className="our-cofounders">
          <div className="left-vector">
            <SBackgroundLeft />
          </div>

          <div className="our-cofounders-wrap">
            <div className="words-cofounders">
              <div className="home-heading">
                Words of <br />
                our founders
              </div>
              <div className="paragraph-text">
                Helping candidates find their dream job and helping businesses
                find the best talent can be incredibly rewarding. Working for a
                tech recruitment agency can provide employees with a sense of
                purpose and the opportunity to make a positive impact on
                people’s lives.
              </div>
              <DividerQuote />
              <SliderControls
                fullName={"Peter Chulu"}
                position={"Founder"}
                imgSrc={
                  "https://res.cloudinary.com/dhbe6wtod/image/upload/v1719163235/werkix/logos/logo-alt_axx0af.jpg"
                }
              />
            </div>{" "}
            <div className="cofounders-video">
              {" "}
              <NormalVideoPlayer />
            </div>
          </div>
        </div>
        <div className="right-vector">
          <SBackgroundRight />
        </div>
      </div>
    </motion.div>
  );
};

export default AboutPage;
