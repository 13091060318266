import React from "react";
import "./blogHome.styles.css";
import BlogCard from "../cards/blogCard/blobCard.component";
import { BounceLoader } from "react-spinners";
import SadEmoj from "../../svg/sadEmoj";

const BlogHome = ({ posts, error, loading, getPostDate, extractHeading }) => {
  return (
    <div id="blogs-articles" className="blog-home">
      <div className="blog-wrap">
        <div className="blog-wrapper">
          <div className="blog-header">
            <div className="home-title">Blog & Articles</div>
            <div className="home-heading">
              Check our latest news <br />& happenings
            </div>
          </div>

          <div className="blog-article">
            {error ? (
              <div className="posts-error">
                <SadEmoj />
                {error}
              </div>
            ) : loading ? (
              <div className="loading-state">
                <BounceLoader
                  color="#67fb94"
                  loading={loading}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : posts.length > 0 ? (
              <>
                {" "}
                {posts.slice(0, 3).map((post, index) => (
                  <BlogCard
                    key={index}
                    first={index === 0}
                    last={index === 2}
                    title={extractHeading(extractHeading(post.text?.text, 80))}
                    date={getPostDate(post.created.time)}
                    cardImage={
                      post.content?.contentEntities?.length > 0
                        ? post.content?.contentEntities[0]?.entityLocation
                        : "https://res.cloudinary.com/dhbe6wtod/image/upload/v1721319960/werkix/blog/blog01_zoooab.jpg"
                    }
                  />
                ))}{" "}
              </>
            ) : (
              <p style={{ color: "#fff" }}>No posts found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogHome;
