import React from "react";
import "./buttons.style.css";
import Arrow from "../../svg/ourserviceHome/arrow";

const ArrowButton = ({ right, fill, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`button-container ${right ? "right-button" : "left-btn"} `}
    >
      {" "}
      <Arrow fill={`${fill}`} />
    </div>
  );
};

export default ArrowButton;
