import React, { useState } from "react";
import "./contact.style.css";
import PageHeader from "../../components/pageHeader/pageHeader.component.jsx";
import ContactCard from "../../components/cards/contactCard/contactCard.component.jsx";
import Contacts from "../../data/contactCard.js";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { getError } from "../../helpers/errors.helper";
import axios from "axios";
import InputComponent from "../../components/inputs/inputField.component.jsx";
import TextArea from "../../components/inputs/textArea.component.jsx";
import PulseLoader from "react-spinners/PulseLoader";
import SendFormArrow from "../../svg/contactPage/sendFormArrow.js";
import XVector from "../../svg/contactPage/xVector.js";
import MapMarker from "../../vectors/mapMarker.js";

const ContactPage = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const contactInfo = {
    fullName: "",
    email: "",
    subject: "",
    message: "",
  };
  const [contact, setContact] = useState(contactInfo);
  const { fullName, email, subject, message } = contact;
  const handleContact = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleValidation = Yup.object({
    fullName: Yup.string()
      .required("Name is required")
      .min(2, "name but have more than 2 character")
      .max(16, "name must be less than 16 characters")
      .matches(/[aA-zZ]+$/, "Number and special characters are not allowed"),
    email: Yup.string()
      .required("Email Address is required.")
      .email("Invalid email address"),
    subject: Yup.string().required("subject is required."),
    message: Yup.string().required("message is required").min(20).max(500),
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        "https://werkix-mailing-service-6a39d7327f11.herokuapp.com/api/email/contact-us",
        {
          fullName,
          email,
          subject,
          message,
        }
      );
      setError("");
      setSuccess(data.message);

      setTimeout(() => {
        navigate("/");
      }, 2000);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(getError(err));
    }
  };
  return (
    <div className="contact-page">
      <PageHeader pageName={"contact"} />

      <div className="werkix-map">
        <div className="werkix-overlay"></div>
        <iframe
          title="Werkix LLC"
          width="100%"
          height="600"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Werkix+(Werkix)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps devices</a>
          <div className="map-overlay"></div>
        </iframe>
      </div>
      <div className="contact-container">
        <div className="contact-cards">
          {Contacts.map((contact, i) => (
            <ContactCard
              icon={contact.icon}
              cardNumber={contact.cardId}
              primaryText={contact.primaryText}
              secondaryText={contact.secondaryText}
              alternativeText={contact.alternativeText}
              key={i}
              location={contact.location}
            />
          ))}
        </div>
      </div>

      <div className="contact-form-container">
        <div className="contact-form-vectors">
          <XVector />
          <XVector />
          <XVector />
          <XVector />
          <XVector />
          <XVector />
          <XVector />
          <XVector />
          <XVector />
          <XVector />
          <XVector />
          <XVector />
        </div>
        <div className="contact-form">
          <div className="home-title">Contact us now</div>
          <div className="home-heading">
            Request an appointment <br /> with us
          </div>
          <h3>Incubate, Train, and Monitor only the best tech talent.</h3>
          <Formik
            enableReinitialize
            validationSchema={handleValidation}
            initialValues={{ fullName, subject, email, message }}
            onSubmit={() => {
              handleSubmit();
            }}
          >
            {(form) => (
              <Form className="form-content">
                {" "}
                <div className="name-email">
                  <InputComponent
                    type="text"
                    placeholder="Your Name"
                    name="fullName"
                    onChange={handleContact}
                  />
                  <InputComponent
                    type="text"
                    name="email"
                    placeholder="Email"
                    onChange={handleContact}
                  />
                </div>
                <InputComponent
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  onChange={handleContact}
                />
                <TextArea
                  name="message"
                  placeholder="Write your message"
                  onChange={handleContact}
                ></TextArea>
                {error && <div className="input-error">{error}</div>}
                {success && <div className="input-success">{success}</div>}
                <button type="submit" className="submit-btn" disabled={loading}>
                  Send <SendFormArrow stroke={"#0C0F14"} />
                </button>
                <PulseLoader
                  color="#67fb94"
                  loading={loading}
                  size={14}
                  speedMultiplier={1}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
