import React from "react";

const EnvelopIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="55"
      viewBox="0 0 68 55"
      fill="none"
    >
      <path
        d="M24.6707 34.9228L24.6722 34.9243C26.598 36.7994 28.5296 37.7331 30.691 37.7331C32.861 37.7331 34.7645 36.798 36.6888 34.9243L36.6903 34.9228L37.885 33.7498L56.8832 52.6201C55.9333 53.2154 54.4627 53.5611 52.426 53.5611H9.41803C7.1753 53.5611 5.57197 53.2091 4.54246 52.5898L23.4764 33.7501L24.6707 34.9228ZM60.4535 26.5604L60.4606 26.5583L60.4676 26.5559L60.473 26.5541V45.0311C60.473 46.5417 60.3366 47.6644 60.0803 48.4679L41.6076 30.122L47.2604 24.5382C49.7798 26.2342 52.7901 27.2121 56.024 27.2121C57.5568 27.2121 59.0479 26.9879 60.4535 26.5604ZM40.8474 7.08008C40.4392 8.46044 40.236 9.91509 40.236 11.4241C40.236 14.946 41.408 18.2001 43.3735 20.8304L43.3734 20.8305L43.3816 20.8409L43.5254 21.0231L33.7753 30.7257L33.7745 30.7265C32.5923 31.9087 31.6765 32.3651 30.691 32.3651C29.8133 32.3651 28.9849 31.9952 27.9613 31.0664L27.6137 30.7257L27.6111 30.723L4.67673 7.89865C5.48157 7.4295 6.68565 7.13849 8.32991 7.08698L8.88912 7.08008H40.8474ZM0.832031 15.5821C0.832031 13.9392 1.01533 12.7345 1.33264 11.9006L19.7816 30.1221L1.2687 48.5146C1.02986 47.8268 0.875099 46.8714 0.838947 45.616L0.832031 45.0311V45.0281V15.5821Z"
        stroke="#67FB94"
      />
      <path
        d="M56.0248 0L56.5288 0.0139998C62.5557 0.28 67.4487 5.334 67.4487 11.424C67.4487 17.654 62.2828 22.82 56.0248 22.82C49.7668 22.82 44.5938 17.682 44.5938 11.424C44.5938 5.313 49.4868 0.28 55.5138 0.0139998L56.0248 0Z"
        fill="#67FB94"
      />
    </svg>
  );
};

export default EnvelopIcon;
