import React from "react";

const MenuOpenX = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 48 48"
      id="letter-x"
    >
      <path
        fill="#fff"
        fill-rule="evenodd"
        d="M35.2804 10.4636C36.129 11.1707 36.2436 12.4319 35.5365 13.2804L26.6035 24.0001L35.5365 34.7197C36.2436 35.5682 36.129 36.8294 35.2804 37.5365C34.4319 38.2436 33.1707 38.129 32.4636 37.2804L24 27.1242L15.5365 37.2804C14.8294 38.129 13.5682 38.2436 12.7197 37.5365C11.8711 36.8294 11.7565 35.5682 12.4636 34.7197L21.3966 24.0001L12.4636 13.2804C11.7565 12.4319 11.8711 11.1707 12.7197 10.4636C13.5682 9.75648 14.8294 9.87113 15.5365 10.7197L24 20.876L32.4636 10.7197C33.1707 9.87113 34.4319 9.75648 35.2804 10.4636Z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};

export default MenuOpenX;
