import React from "react";

const RightTestimonialBg = ({ opacity }) => {
  return (
    <svg
      width="826"
      height="873"
      viewBox="0 0 826 873"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity} clip-path="url(#clip0_192_519)">
        <path
          d="M879.657 371.761L375.017 596.507L74.8101 527.868L536.313 290L942.527 668.516L176.317 330.516L298.93 139.838L452.41 452.323L375.017 596.507L327.949 397.421"
          stroke="#66FA93"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M576.189 506.917L536.313 290L452.41 452.322"
          stroke="#66FA93"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M301.788 153.327C308.729 151.634 313.076 144.222 311.498 136.772C309.919 129.323 303.013 124.656 296.072 126.349C289.131 128.042 284.784 135.453 286.362 142.903C287.941 150.353 294.847 155.019 301.788 153.327Z"
          fill="#27F7EA"
        />
        <path
          d="M455.106 465.047C462.047 463.354 466.394 455.943 464.816 448.493C463.237 441.043 456.331 436.377 449.39 438.07C442.449 439.762 438.102 447.174 439.68 454.624C441.259 462.073 448.165 466.74 455.106 465.047Z"
          fill="#27F7EA"
        />
        <path
          d="M80.6321 537.466C85.2594 536.338 88.1576 531.396 87.1054 526.43C86.0533 521.464 81.4491 518.352 76.8218 519.481C72.1945 520.61 69.2963 525.551 70.3485 530.517C71.4007 535.483 76.0049 538.595 80.6321 537.466Z"
          fill="#27F7EA"
        />
        <path
          d="M717.69 577.571C722.317 576.442 725.215 571.501 724.163 566.535C723.111 561.568 718.506 558.457 713.879 559.585C709.252 560.714 706.354 565.655 707.406 570.621C708.458 575.588 713.062 578.699 717.69 577.571Z"
          fill="#27F7EA"
        />
        <path
          d="M779.136 604.337C783.764 603.209 786.662 598.268 785.61 593.301C784.557 588.335 779.953 585.223 775.326 586.352C770.699 587.481 767.8 592.422 768.853 597.388C769.905 602.355 774.509 605.466 779.136 604.337Z"
          fill="#27F7EA"
        />
        <path
          d="M538.219 298.992C542.846 297.864 545.744 292.923 544.692 287.956C543.64 282.99 539.036 279.879 534.408 281.007C529.781 282.136 526.883 287.077 527.935 292.043C528.987 297.01 533.591 300.121 538.219 298.992Z"
          fill="#27F7EA"
        />
        <path
          d="M476.478 328.237C481.106 327.108 484.004 322.167 482.952 317.201C481.899 312.234 477.295 309.123 472.668 310.251C468.041 311.38 465.143 316.321 466.195 321.288C467.247 326.254 471.851 329.365 476.478 328.237Z"
          fill="#27F7EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_192_519">
          <rect
            width="837.957"
            height="689.414"
            fill="white"
            transform="matrix(0.971521 -0.236953 0.207259 0.978286 0.0195312 198.556)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RightTestimonialBg;
