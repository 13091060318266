import React from "react";
import "./clientTestimonialCard.styles.css";
import TestimoQuoteIcon from "../../../svg/testimoQuote";

const ClientTestimonialCard = ({ logo, testimony, fullName, position }) => {
  return (
    <div className="client-testimonial-card">
      <div className="client-testimo-card">
        <div className="testimo-quote">
          <TestimoQuoteIcon />
        </div>
        <p>{testimony}</p>
        <div className="lower-testimony">
          <div className="client-testimo-divider">
            {" "}
            <div className="testimo-logo">
              <img src={logo} alt="client-logo" />
            </div>
          </div>

          <div className="testifier">{fullName}</div>
          <div className="testifier-position">{position}</div>
        </div>
      </div>
    </div>
  );
};

export default ClientTestimonialCard;
