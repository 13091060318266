import React from "react";

const ClockIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.43756 3.3125C5.43756 3.00781 5.67194 2.75 6.00006 2.75C6.30475 2.75 6.56256 3.00781 6.56256 3.3125V6.21875L8.55475 7.53125C8.81256 7.71875 8.88287 8.07031 8.69537 8.32812C8.53131 8.58594 8.17975 8.65625 7.92194 8.46875L5.67194 6.96875C5.53131 6.875 5.43756 6.6875 5.43756 6.5V3.3125ZM6.00006 0.5C9.30475 0.5 12.0001 3.19531 12.0001 6.5C12.0001 9.82812 9.30475 12.5 6.00006 12.5C2.67194 12.5 6.10352e-05 9.82812 6.10352e-05 6.5C6.10352e-05 3.19531 2.67194 0.5 6.00006 0.5ZM1.12506 6.5C1.12506 9.19531 3.30475 11.375 6.00006 11.375C8.67194 11.375 10.8751 9.19531 10.8751 6.5C10.8751 3.82812 8.67194 1.625 6.00006 1.625C3.30475 1.625 1.12506 3.82812 1.12506 6.5Z"
        fill="#66FA93"
      />
    </svg>
  );
};

export default ClockIcon;
