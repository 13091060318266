import React from "react";

const ArrowRight = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.05" cx="20" cy="20" r="20" fill="white" />
      <path
        d="M24.7891 20.0156L21.0391 23.7656C20.7578 24.0703 20.2656 24.0703 19.9844 23.7656C19.6797 23.4844 19.6797 22.9922 19.9844 22.7109L22.4453 20.25L15.25 20.25C14.8516 20.25 14.5 19.8984 14.5 19.5C14.5 19.0781 14.8516 18.75 15.25 18.75L22.4453 18.75L19.9844 16.2656C19.6797 15.9844 19.6797 15.4922 19.9844 15.2109C20.2656 14.9062 20.7578 14.9062 21.0391 15.2109L24.7891 18.9609C25.0938 19.2422 25.0938 19.7344 24.7891 20.0156Z"
        fill="#66FA93"
      />
    </svg>
  );
};

export default ArrowRight;
