import React from "react";
import XVectorGreen from "../../svg/contactPage/xVectorGreen";

const AboutGallery = () => {
  return (
    <div className="image-container">
      <div className="image-gallery">
        <img
          src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1719420029/werkix/about-main/about2_pllzid.jpg"
          alt=""
        />
        <img
          src="https://res.cloudinary.com/dhbe6wtod/image/upload/v1719420026/werkix/about-main/about1_jyyz9a.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default AboutGallery;
