import React from "react";
import FbPJIcon from "../../../svg/clients/socials/fbIcon";
import XPJIcon from "../../../svg/clients/socials/xIcon";
import PinterestPJIcon from "../../../svg/clients/socials/pinterestIcon";

const CaseStudyCard = ({
  image,
  name,
  client,
  status,
  summary,
  challenge,
  results,
  solution,
  x,
  meta,
  pinterest,
}) => {
  return (
    <div className="project-card-container">
      <div className="project-summary">
        <div className="project-picture">
          <img src={`${image}`} alt="project-pic" />
        </div>
        <div className="project-summary-info">
          <div className="project-title">
            <h3 className="pj-heading">Project</h3>
            <p className="pj-heading-desc">{name}</p>
          </div>
          <div className="project-client">
            <h3 className="pj-heading">Client</h3>
            <p className="pj-heading-desc">{client}</p>
          </div>
          <div className="project-status">
            <h3 className="pj-heading">Status</h3>
            <p className="pj-heading-desc">{status}</p>
          </div>
          <div className="project-socials">
            <a
              href={`${x}`}
              target="_blank"
              rel="nooper noreferrer"
              className="social-link"
            >
              <XPJIcon fill={"#fff"} />
            </a>
            <a
              href={`${meta}`}
              target="_blank"
              rel="nooper noreferrer"
              className="social-link"
            >
              <FbPJIcon />
            </a>
            <a
              href={`${pinterest}`}
              target="_blank"
              rel="nooper noreferrer"
              className="social-link"
            >
              <PinterestPJIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="home-heading project-overview">Project Overview</div>

      <div className="project-details">
        <div className="project-desc">
          <div className="summary-challenge">
            <div className="summary">
              <h3 className="pj-title"> Summary </h3> <p>{summary}</p>
            </div>

            <div className="challenge">
              <h3 className="pj-title">Client Challenge: </h3>
              <p>{challenge}</p>
            </div>
          </div>
          <div className="project-results">
            <h3 className="pj-title">Results</h3>
            <p>{results}</p>
          </div>
        </div>
        <div className="solution">
          <h3 className="pj-title">Solution: </h3>
          <p>{solution}</p>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyCard;
