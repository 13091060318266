const talentTestimonies = [
  {
    fullName: "Blessings",
    position: "Full Stack Developer",
    profile:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1729872909/werkix/talent/blessings_plqzcc.jpg",
    testimony:
      "Working at Werkix has been an incredible journey, allowing me to showcase my tech skills on a global stage with Stratis Global. The opportunity to collaborate with a US-based client has greatly enhanced my professional growth and broadened my expertise.",
  },
  {
    fullName: "Madalitso ",
    position: "Princical Salesforce Developer",
    profile:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1726497991/werkix/talent/mada_yyewbl.jpg",
    testimony:
      "I have greatly benefited from my time at Werkix, where i have not only upskilled with Salesforce but also had the chance to tackle diverse projects with Stratis Global. The growth and versatility in the tech field exemplify the exciting opportunities Werkix provides for professional development",
  },
  {
    fullName: "Sandram",
    position: "IT Support Engineer",
    profile:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1726497989/werkix/talent/sandy_kcgbmm.jpg",
    testimony:
      "Working at Werkix has been a transformative experience for me, allowing me to grow into a Support Engineer and work with Rojoli Services, a US-based client. The opportunities and mentorship I’ve received have truly elevated my technical skills and global career outlook.",
  },
  {
    fullName: "Alex",
    position: "Cybersecurity Engineer",
    profile:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1726497983/werkix/talent/alex_iwqzs9.jpg",
    testimony:
      "You Get A Different Perspective On Teching And Also Enjoy A Community That Helps People Grow In So Many Different Ways. Abstract Equipped Me With What I Needed To Get Into The Door. If I hadn't Been To Abstract, I'd Know Nothing.",
  },
];
export default talentTestimonies;
