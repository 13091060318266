import React from "react";

const FbPJIcon = ({ fill }) => {
  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.44922 5.875H3.98438V10.25H2.03125V5.875H0.429688V4.07812H2.03125V2.69141C2.03125 1.12891 2.96875 0.25 4.39453 0.25C5.07812 0.25 5.80078 0.386719 5.80078 0.386719V1.92969H5C4.21875 1.92969 3.98438 2.39844 3.98438 2.90625V4.07812H5.72266L5.44922 5.875Z"
        fill={fill}
      />
    </svg>
  );
};

export default FbPJIcon;
