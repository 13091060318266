import React from "react";

const SadEmoj = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 32 32"
      viewBox="0 0 32 32"
      id="sad-emoticon"
      fill="#7ac14b"
      width={70}
    >
      <path d="M16,0.5C7.45001,0.5,0.5,7.45001,0.5,16S7.45001,31.5,16,31.5S31.5,24.54999,31.5,16S24.54999,0.5,16,0.5z M10.10999,9.26996c1.19,0,2.15002,0.96002,2.15002,2.15002c0,1.17999-0.96002,2.15002-2.15002,2.15002s-2.14996-0.97003-2.14996-2.15002C7.96002,10.22998,8.91998,9.26996,10.10999,9.26996z M9.28998,23.78998c-0.58997-0.58002-0.58997-1.52997,0-2.12C11.09003,19.87,13.46997,18.88,16,18.88s4.90997,0.98999,6.71002,2.78998c0.58997,0.59003,0.58997,1.53998,0,2.12c-0.29004,0.28998-0.67999,0.44-1.06,0.44s-0.77002-0.15002-1.06-0.44C19.35999,22.56,17.72998,21.88,16,21.88s-3.35999,0.67999-4.59003,1.90997C10.82001,24.38,9.88,24.38,9.28998,23.78998z M21.89001,13.57001c-1.19,0-2.15002-0.97003-2.15002-2.15002c0-1.19,0.96002-2.15002,2.15002-2.15002s2.14996,0.96002,2.14996,2.15002C24.03998,12.59998,23.08002,13.57001,21.89001,13.57001z"></path>
    </svg>
  );
};

export default SadEmoj;
