import React from "react";

const JobSourcingIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill={fill}
    >
      <g clip-path="url(#clip0_275_1744)">
        <path
          d="M69.6793 16.0943C69.884 15.8892 69.999 15.6113 69.999 15.3216C69.999 15.0318 69.884 14.7539 69.6793 14.5488L55.4441 0.320235C55.239 0.115189 54.9609 0 54.6709 0C54.3808 0 54.1027 0.115189 53.8976 0.320235L39.9676 14.2437H28.3815C28.0987 10.5797 26.4078 7.16772 23.6636 4.72358C20.9194 2.27945 17.3352 0.993183 13.6631 1.13468C9.99096 1.27618 6.51636 2.83445 3.96838 5.48251C1.42039 8.13057 -0.00292969 11.6626 -0.00292969 15.3374C-0.00292969 19.0123 1.42039 22.5443 3.96838 25.1923C6.51636 27.8404 9.99096 29.3987 13.6631 29.5402C17.3352 29.6817 20.9194 28.3954 23.6636 25.9513C26.4078 23.5071 28.0987 20.0951 28.3815 16.4312H39.9993L53.5935 30.0177V41.5754H41.5623C41.2722 41.5754 40.994 41.6906 40.7889 41.8957C40.5838 42.1009 40.4685 42.3791 40.4685 42.6691V68.906C40.4685 69.1961 40.5838 69.4743 40.7889 69.6794C40.994 69.8845 41.2722 69.9998 41.5623 69.9998H67.8123C68.1023 69.9998 68.3806 69.8845 68.5857 69.6794C68.7908 69.4743 68.906 69.1961 68.906 68.906V42.668C68.906 42.378 68.7908 42.0998 68.5857 41.8946C68.3806 41.6895 68.1023 41.5743 67.8123 41.5743H55.781V29.9805L69.6793 16.0943ZM14.2185 27.3632C11.8387 27.3632 9.51239 26.6574 7.53375 25.3352C5.5551 24.0129 4.01303 22.1336 3.10257 19.9349C2.19211 17.7361 1.95417 15.3167 2.41885 12.9828C2.88352 10.6488 4.02993 8.50502 5.71308 6.82264C7.39622 5.14026 9.5405 3.99483 11.8747 3.53121C14.2089 3.0676 16.6281 3.30664 18.8265 4.2181C21.0248 5.12955 22.9034 6.67248 24.2248 8.65173C25.5462 10.631 26.2508 12.9576 26.2498 15.3374C26.2454 18.5265 24.9763 21.5836 22.7208 23.8381C20.4653 26.0926 17.4076 27.3603 14.2185 27.3632ZM66.7185 43.7618V67.8123H42.656V43.7618H66.7185ZM54.6709 27.9998L41.9834 15.3123L54.6709 2.63023L67.3584 15.3177L54.6709 27.9998Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_275_1744">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JobSourcingIcon;
