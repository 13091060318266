import React from "react";

const Quotes = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 9265">
        <path
          id="Vector 1"
          d="M7.21842 2.1344L3.32597 0L0.479248 7.76685L1.98975 8.47832L7.21842 2.1344Z"
          fill="#66FA93"
        />
        <path
          id="Vector 2"
          d="M13.3053 3.65637L9.41288 1.52197L6.56616 9.28882L8.07667 10.0003L13.3053 3.65637Z"
          fill="#66FA93"
        />
      </g>
    </svg>
  );
};

export default Quotes;
