const caseStudies = [
  {
    id: 1,
    name: "The implementation of HK Mobile",
    subheading:
      "Stratis Global Transforms Salesforce Heroku Access with HK Mobile",
    client: "Stratis | Pewani Labs",
    status: "completed",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1722338670/werkix/clients/header-slide-1_1_qa6uxv.png",
    summary:
      " HK Mobile revolutionized Stratis Global's Salesforce Heroku access, delivering unprecedented efficiency and transparency.",
    challenge:
      "Stratis Global faced productivity issues using Salesforce Heroku talent to develop a solution.",
    solution:
      "HK Mobile transformed the experience with a new mobile app offering: Seamless Access: Easy app access. Detailed Billing and Usage Representation: Graphical billing and usage views. Enterprise Contract Alignment: View usage against contract terms.",
    results:
      "  Integrating HK Mobile led to: Effortless Management: Easier app, team, and space management, boosting productivity. Customization and Monitoring: Customizable apps and teams with real-time monitoring. Enhanced Efficiency: Improved operational efficiency by monitoring builds and releases.",
    socialLinks: {
      x: "https://www.twitter.com",
      meta: "https://www.facebook.com",
      pinterest: "https://www",
    },
  },
  {
    id: 2,
    name: "Scaling Customer Support",
    subheading: "Enhacning IT  Support",
    client: "Rojoli Services",
    status: "completed",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1718628311/werkix/home/DSC02902_b672cs.jpg",
    summary:
      "Transforming Customer Support Efficiency with Managed Support Engineers",
    challenge:
      "Rojoli Services, a rapidly growing Managed Services Provider in Atlanta, faced a significant challenge: managing increased customer support demands. Their in-house resources were overstretched, resulting in slow response times and declining customer satisfaction.",
    solution:
      "To tackle these challenges, Rojoli Services partnered with Werkix to outsource part of their customer support. Werkix provided a managed support engineer solution that included:",
    results:
      " The Rojoli-Werkix partnership yielded remarkable results:20% Reduction in Response Times, 30% Increase in Customer Satisfaction 25% Cost Reduction, Scalability: Managed engineers handled varying support demands seamlessly.Added Value: Development of KPIs and training in essential management tools.",
    socialLinks: {
      x: "https://www.twitter.com",
      meta: "https://www.facebook.com",
      pinterest: "https://www",
    },
  },
  {
    id: 3,
    name: "Atlis AI",
    subheading: "Revolutionizing AI Integration with Stratis Global",
    client: "Stratis Global",
    status: "In progress",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1719420026/werkix/about-main/about3_rccfnk.jpg",
    summary: "Atlis AI: Revolutionizing AI Integration with Stratis Global",
    challenge:
      "In the rapidly evolving field of AI, security and privacy are critical, especially in sectors like healthcare and finance. Stratis Global prioritized protecting customer data over integrating public AI solutions like OpenAI or Anthropic. They needed a secure, private AI solution and turned to Werkix for robust AI engineers to integrate a solution.",
    solution:
      "To address these needs, Werkix engineers worked with Stratis Global and created the Atlis AI Assistant. The solution offers three key benefits; Private Integration, sSeamless Data Integration, Advance AI Features",
    results:
      "With Atlis AI Assistant, Stratis Global saw the following results: Developed a private LLM service using Llama3 and QWEN2, ensuring data privacy. Implemented a private embedding model service, enhancing RAG capabilities. Deployed a Deepchat-based chat widget on internal and external websites, improving customer support. Created a user-friendly web application to manage data, chat widgets, AI models, and agents, streamlining operations.",
    socialLinks: {
      x: "https://www.twitter.com",
      meta: "https://www.facebook.com",
      pinterest: "https://www",
    },
  },
  {
    id: 4,
    name: "Heroku Access with HK Mobile",
    client: "Stratis Global",
    status: "In progress",
    image:
      "https://res.cloudinary.com/dhbe6wtod/image/upload/v1718628320/werkix/home/DSC02924-Edit_tyyld4.jpg",
    summary:
      " Stratis Global Transforms Salesforce Heroku Access with HK Mobile",
    challenge:
      "Stratis Global faced productivity issues using Salesforce Heroku through a web browser. Werkix, a partner, provided skilled talent to develop a solution.",
    solution:
      "To tackle these challenges, Rojoli Services partnered with Werkix to outsource part of their customer support. Werkix provided a managed support engineer solution that included:",
    results:
      " The Rojoli-Werkix partnership yielded remarkable results:20% Reduction in Response Times, 30% Increase in Customer Satisfaction 25% Cost Reduction, Scalability: Managed engineers handled varying support demands seamlessly.Added Value: Development of KPIs and training in essential management tools.",
    socialLinks: {
      x: "https://www.twitter.com",
      meta: "https://www.facebook.com",
      pinterest: "https://www",
    },
  },
];

export default caseStudies;
