import React from "react";

const BottomLeftVectors = () => {
  return (
    <svg
      width="381"
      height="553"
      viewBox="0 0 381 553"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M381 553H317.074L-197 61.1613V0L381 553Z"
        fill="#67FB94"
        fill-opacity="0.1"
      />
      <path
        d="M235.365 553.003H135.826L-173 244.545V145.125L235.365 553.003Z"
        fill="#161D27"
      />
    </svg>
  );
};

export default BottomLeftVectors;
