import Diversity from "../svg/ourserviceHome/diversity";
import ExpertiseAndQuality from "../svg/ourserviceHome/expertiseAndQuality";
import GlobalReach from "../svg/ourserviceHome/globalReach";

const whyWerkix = [
  {
    title: {
      main: "Expertise",
      subtitle: "and Quality",
    },
    icon: <ExpertiseAndQuality />,
    serviceDesc: {
      primary:
        "Rigorous Screening Process: We are committed to providing only the best tech talent. Our stringent vetting ensures that every professional we connect you with has the skills, experience, and creativity to excel.",
      secondary:
        "Top-Tier Talent: We pride ourselves on our ability to source and screen the highest quality tech professionals. You'll gain access to a pool of individuals who are not just skilled but are industry leaders in their fields.",
    },
  },
  {
    title: {
      main: "Global Reach",
      subtitle: "Local Focus",
    },
    icon: <GlobalReach />,
    serviceDesc: {
      primary:
        " Connecting Continents: We specialize in linking African professionals with opportunities in the USA and Europe. Our deep understanding of both local and international markets ensures seamless integration and collaboration.",
      secondary:
        " Market Expertise: Our team understands the nuances and demands of different markets. This duality of focus allows us to tailor our approach, ensuring successful placements and satisfied clients.",
    },
  },
  {
    title: {
      main: "Diversity ",
      subtitle: "and Inclusion",
    },
    icon: <Diversity />,
    serviceDesc: {
      primary:
        "Fostering Inclusive Environments: At Werkix, we promote diversity by championing African talent. We believe in the power of varied perspectives and experiences to drive technological advancements and innovation.",
      secondary:
        "Empowering Talent: We are dedicated to creating opportunities that empower African professionals to shine on the global stage.",
    },
  },
];

export default whyWerkix;
