import React from "react";
import "./ourTeamHome.styles.css";
import TeamMemberCard from "../cards/teamMember/teamMember.component";
import teamMembers from "../../data/teamMembers";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderButtonRight from "../slider-button/sliderButtonRight.component";
import SliderButtonLeft from "../slider-button/sliderButtonLeft.component";

const OurTeamHome = () => {
  return (
    <div id="meet-our-team" className="our-team-home">
      <div className="our-team-wrap">
        <div className="our-team-wrapper">
          <div className="our-team-header">
            <div className="home-title">Work & Supporter</div>
            <div className="home-heading">Meet Our Team</div>
          </div>

          <div className="profile">
            {teamMembers.map((member) => (
              <TeamMemberCard
                id={member.id}
                mainCard
                image={member.image}
                name={member.name}
                position={member.position}
                key={member.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeamHome;
