import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
    >
      <path
        d="M52.5502 23.7653L52.5503 23.7661C52.7185 25.0945 51.7793 26.3077 50.4427 26.4762L50.4131 26.48L50.384 26.4872C50.3422 26.4977 50.2864 26.5003 50.1553 26.5003C48.9489 26.5003 47.9129 25.6082 47.7679 24.373L47.7674 24.3688C46.5698 14.8672 39.1324 7.42978 29.6308 6.23222L29.6308 6.23215L29.6226 6.23125C28.2998 6.08684 27.3552 4.88515 27.5233 3.55711L27.5234 3.55712L27.5243 3.54856C27.6683 2.22956 28.875 1.27817 30.2103 1.42194C41.9094 2.88617 51.0877 12.0656 52.5502 23.7653ZM27.602 15.1081L27.6025 15.1061C27.9394 13.7991 29.2685 13.0293 30.5474 13.3649L30.5494 13.3655C35.473 14.6352 39.3341 18.4919 40.6617 23.4767C40.9727 24.7603 40.1982 26.088 38.9229 26.4317C38.7359 26.4765 38.5123 26.5003 38.3183 26.5003C37.2284 26.5003 36.2652 25.7772 35.9735 24.6879C35.109 21.4225 32.5804 18.8903 29.3438 18.0603C28.0321 17.7156 27.2603 16.411 27.602 15.1081Z"
        stroke="#67FB94"
      />
      <path
        opacity="0.4"
        d="M40.5638 36.66L40.5591 36.6594C39.0871 36.4856 37.6404 36.9946 36.6025 38.0324L31.2335 43.4014L30.7201 43.9149L30.0729 43.5856C21.6325 39.2902 14.7092 32.3962 10.4126 23.9247L10.0846 23.278L10.5973 22.7652L15.9943 17.3682C17.032 16.3305 17.5422 14.8826 17.3739 13.408C17.3739 13.4079 17.3739 13.4078 17.3739 13.4078L16.5274 6.06226C16.5273 6.0617 16.5273 6.06114 16.5272 6.06058C16.2358 3.61405 14.1817 1.77832 11.7174 1.77832H6.67043C3.91197 1.77832 1.66652 4.07285 1.83741 6.75575L1.83753 6.75761C3.34634 31.1593 22.8661 50.6522 47.2409 52.1682L40.5638 36.66ZM40.5638 36.66L47.9712 37.4993C47.9721 37.4994 47.9729 37.4995 47.9738 37.4996C50.4162 37.7917 52.2435 39.8531 52.2205 42.2718L52.2204 42.2718M40.5638 36.66L52.2204 42.2718M52.2204 42.2718V42.2813M52.2204 42.2718V42.2813M52.2204 42.2813V47.3283C52.2204 50.0861 49.9262 52.3332 47.2415 52.1682L52.2204 42.2813Z"
        stroke="#67FB94"
        stroke-width="2"
      />
    </svg>
  );
};

export default PhoneIcon;
