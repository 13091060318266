import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion, useInView, useAnimation, delay } from "framer-motion";

const CaseStudyColumn = ({ casestudy }) => {
  const { id, name, image, subheading } = casestudy;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <Link to={`/clients/${id}`} className="grid-item">
      {" "}
      <motion.img
        variants={{
          hidden: { opacity: 0, x: 75 },
          visible: { opacity: 0.5, x: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 1.5 }}
        ref={ref}
        src={casestudy.image}
        alt=""
      />
      <div className="case-desc">
        {" "}
        <span className="case-study-subheading">{casestudy.subheading}</span>
        <span className="case-study-heading">{casestudy.name}</span>
      </div>
    </Link>
  );
};

export default CaseStudyColumn;
