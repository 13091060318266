import React from "react";

const CandidateReportIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill={fill}
    >
      <path
        d="M68.2752 37.837L52.5 30.475V9.29673C52.5 9.08863 52.4406 8.88486 52.3288 8.70934C52.217 8.53381 52.0575 8.39382 51.8689 8.3058L35.4627 0.649546C35.3178 0.581921 35.1599 0.546875 35 0.546875C34.8401 0.546875 34.6822 0.581921 34.5373 0.649546L18.1311 8.3058C17.9425 8.39382 17.783 8.53381 17.6712 8.70934C17.5594 8.88486 17.5 9.08863 17.5 9.29673V30.475L1.72484 37.837C1.53628 37.9251 1.37674 38.0651 1.26495 38.2406C1.15317 38.4161 1.09378 38.6199 1.09375 38.828V60.703C1.09378 60.9111 1.15317 61.1149 1.26495 61.2904C1.37674 61.4659 1.53628 61.6059 1.72484 61.6939L18.1311 69.3502C18.276 69.4178 18.4339 69.4528 18.5938 69.4528C18.7536 69.4528 18.9115 69.4178 19.0564 69.3502L35 61.9061L50.9436 69.3436C51.0885 69.4112 51.2464 69.4463 51.4062 69.4463C51.5661 69.4463 51.724 69.4112 51.8689 69.3436L68.2752 61.6874C68.4627 61.5998 68.6216 61.4608 68.7333 61.2866C68.845 61.1123 68.905 60.91 68.9062 60.703V38.828C68.9062 38.6199 68.8468 38.4161 68.735 38.2406C68.6233 38.0651 68.4637 37.9251 68.2752 37.837ZM33.9062 60.0063L18.5938 67.1561L3.28125 60.0063V39.5247L18.5938 32.3749L33.9062 39.5203V60.0063ZM19.6875 30.475V9.99345L35 2.84361L50.3125 9.99345V30.475L35 37.6249L19.6875 30.475ZM66.7188 60.0063L51.4062 67.1561L36.0938 60.0063V39.5247L51.4062 32.3749L66.7188 39.5203V60.0063Z"
        fill={fill}
      />
    </svg>
  );
};

export default CandidateReportIcon;
