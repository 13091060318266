import React from "react";

const TestimoQuoteIcon = () => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 2048 1574"
      width="1280"
      height="1280"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform="translate(1548,495)"
        d="m0 0h10l-5 10-9 16-11 22-12 22-14 25-14 26-15 28-15 27-17 32-12 22-13 24-13 23-12 23-9 16-12 22-13 23-14 27-14 24-16 30-12 22-13 24-12 21 2 8 11 23 8 16 13 27 9 20 19 42 16 34 18 36 13 28 15 36 1 7-2 1-13-4-28-12-25-9-29-11-28-10-69-26-39-14-37-14-43-16-34-12-40-15-21-8-12-6-7-8-15-29-21-41-14-28-11-23-13-26-4-5v-2l-8-1h-22l-4-2-2-5v-8l3-13v-18l-2-19-6-16-4-14v-8l3-8 5-10v-9l-5-16-6-21-10-43-14-54-9-38-6-25-3-14 1-5 15 1 34 1h35l8 7 7 15 22 44 4 5v2l5-5 15-30 8-18 8-16 9-20 7-14 11-25 16-33 8-18 4-8 3-2 74-2 245-2 137-2z"
        fill="#192028"
      />
      <path
        transform="translate(812,616)"
        d="m0 0 15 1 34 1h35l8 7 7 15 22 44 4 5v2l3-1 5 13 14 30 12 23 6 12 10 19 8 16 15 31 4 11 2 17v96l-2 3-19 2h-96l-6-1h-22l-4-2-2-5v-8l3-13v-18l-2-19-6-16-4-14v-8l3-8 5-10v-9l-5-16-6-21-10-43-14-54-9-38-6-25-3-14z"
        fill="#6AFC9A"
      />
      <path
        transform="translate(1103,617)"
        d="m0 0h6l8 7 12 23 16 31 8 16 34 66 11 21 25 50 6 15 1 5v109l-2 1-10 1-49 1h-70l-14-1-3-3-1-88-3-29-8-41-17-67-13-49-10-38-4-20-1-9 25 1h39z"
        fill="#6AFC9A"
      />
      <path
        transform="translate(862,846)"
        d="m0 0 2 2 3 16v8l-3 11h-3l-4-11-1-5v-8l3-8z"
        fill="#1B262B"
      />
    </svg>
  );
};

export default TestimoQuoteIcon;
