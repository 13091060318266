import { ErrorMessage, useField } from "formik";
import React from "react";
import "./inputs.styles.css";

const TextArea = ({ placeholder, bottom, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="input-wrap">
      <textarea
        className={meta.touched && meta.error ? "input-error-border" : ""}
        type={field.type}
        name={field.name}
        minLength={50}
        maxLength={500}
        placeholder={placeholder}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && (
        <div className="input-error">
          {meta.touched && meta.error && <ErrorMessage name={field.name} />}
        </div>
      )}
    </div>
  );
};

export default TextArea;
